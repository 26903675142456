<div class="content-top clearfix" *ngIf="activePage">
  <ul class="breadcrumb al-breadcrumb">
    <li>
      <a class="home-link" mat-icon-button href="/#/dashboard" [routerLink]="'/dashboard'">
        <mat-icon class="md-24">home</mat-icon>
      </a>
    </li>
    <ng-template [ngIf]="activePage.path">
      <li *ngFor="let route of activePage.path; index as i">
        <a [ngClass]="{activeLink: (activePage.path.length - 1) == i}" href="/#/{{ route.link }}"
          [routerLink]="route.link">{{ route.title }}</a>
      </li>
    </ng-template>
    <li>{{ activePage.title }}</li>
  </ul>
</div>
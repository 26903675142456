import { NotificationService } from '../../../notification-service/notification.service'
import { ANIMATION } from '../../../../animations'
import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input
} from '@angular/core'
import { Router } from '@angular/router'
import { GalleryService } from '../../gallery.service'
import { GalleryResource } from '../../share/gallery-resource'
import { isEmpty as _isEmpty } from 'lodash'
import { IConfigNotification } from '../../../notification-service/share/i-config-notification'

const leftSlide = ANIMATION.leftSlide,
  rightSlide = ANIMATION.rightSlide

@Component({
  selector: 'gallery-resource-details',
  templateUrl: 'gallery-resource-details.component.html',
  styleUrls: ['gallery-resource-details.component.scss'],
  animations: [leftSlide, rightSlide]
})
export class GalleryResourceDetailsComponent implements OnInit, OnChanges {
  @Input() moduleConfig: any
  @Input() websiteId: string
  resource: GalleryResource

  isEdit = false

  constructor(
    private notify: NotificationService,
    private router: Router,
    private galleryService: GalleryService) {
  }

  ngOnInit(): void {
    this.subscribeEvents()
    this.loadResource()
  }

  ngOnChanges(change: SimpleChanges): void {
    change.moduleConfig && change.moduleConfig.previousValue &&
      this.router.navigate(['/websites/', change.moduleConfig.previousValue.service.owner, 'resources'])

    change.websiteId && change.websiteId.previousValue && this.loadResource()
  }

  get hasTags(): boolean {
    return !_isEmpty(this.resource.tags)
  }

  private loadResource(): void {
    this.galleryService
      .getFile(this.moduleConfig.service.getFileAction, this.websiteId)
      .subscribe((res: any) => !res.error
        ? this.resorceLoaded(res.data)
        : (this.resource = null, this.handleError(res.error)))
  }

  private handleError(err: any): void {
    this.notify.add(<IConfigNotification>{ type: 'error', title: 'Error !', content: err.message || err.code })
  }

  private resorceLoaded(resource: GalleryResource): void {
    this.resource = resource
  }

  private subscribeEvents(): void {
    this.galleryService
      .resourceUpdateStream$
      .subscribe((res: any) =>
        (this.isEdit = !this.isEdit,
          !res.error && (this.notify.add(<IConfigNotification>{
            type: 'success', title: 'Saved', content: 'Edition info saved'
          }))
        ))
  }
}

import { UserStorageService } from './share/services/user-storage.service'
import { NgModule } from '@angular/core'
import { OverlayContainer, OverlayModule } from '@angular/cdk/overlay'
import { BrowserModule } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatSliderModule } from '@angular/material/slider'
import { ThemeService, SocketIoService } from './share/services'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { GlobalState } from './global.state'
import { AppHttpService } from './share/services/app-http.service'
import { BaImageLoaderService } from './theme/services/ba-image-loader/ba-image-loader.service'
import { BaThemePreloader } from './theme/services/ba-theme-preloader/ba-theme-preloader.service'
import { AppStorage } from './share/services/app-storage.service'
import { SpinnerModule, NotificationModule } from './share/modules'
import { ErrorHandlerService } from './share/services/error-handler.service'
import { HttpClientModule } from '@angular/common/http'

const IMPORTS = [
  AppRoutingModule,
  BrowserModule,
  BrowserAnimationsModule,
  SpinnerModule,
  NotificationModule,
  HttpClientModule,
  OverlayModule,
  RouterModule,
  MatSliderModule
]

const APP_PROVIDERS = [
  UserStorageService,
  GlobalState,
  AppHttpService,
  BaImageLoaderService,
  BaThemePreloader,
  AppStorage,
  SocketIoService,
  OverlayContainer,
  ThemeService,
  ErrorHandlerService,
]

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [...IMPORTS],
  providers: [...APP_PROVIDERS]
})

export class AppModule { }

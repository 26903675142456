import { FormControl } from '@angular/forms'
import { Subject } from 'rxjs'
import { debounceTime, startWith } from 'rxjs/operators'
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Output,
  ViewChildren,
  OnChanges,
  SimpleChanges,
  QueryList
} from '@angular/core'
import {
  assign as _assign,
  filter as _filter,
  head as _head,
  get as _get,
  isEmpty as _isEmpty,
  keys as _keys,
  each as _each
} from 'lodash'
import flatpickr from 'flatpickr'

@Component({
  selector: 'basic-table-filter',
  templateUrl: 'basic-table-filter.component.html',
  styleUrls: ['basic-table-filter.component.scss'],
})

export class BasicTableFilterComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() searchFields: Array<{
    filter: {
      type: string,
      rebuild: boolean,
      range?: boolean,
      list?: any[]
    }
  }>
  @Input() where: any
  @Input() checkboxFilters: any
  @Input() selectFilters: any
  @Input() liveSearchFilters: any
  @Output() onFilter = new EventEmitter<any>()
  @Output() onClearFilter = new EventEmitter<any>()
  @ViewChildren('from') froms: QueryList<any>
  @ViewChildren('to') tos: QueryList<any>

  pickers: any[] = []
  pickerState = false
  textFields: any[]
  dateFields: any[]
  selectFields: any[]
  aheadFields: any[]
  idField: any
  observables: any = {}

  liveSearchControls: any = {}
  liveSearchKeys: string[] = []
  liveSearchLists: any = {}

  constructor() { }

  ngOnInit(): void {
    this.textFields = _filter(this.searchFields, (i: any) => i.filter.type === 'text')
    this.idField = _head(_filter(this.searchFields, (i: any) => i.filter.type === 'id'))
    this.dateFields = _filter(this.searchFields, (i: any) => i.filter.type === 'date')
    this.selectFields = _filter(this.searchFields, (i: any) => i.filter.type === 'select')
    this.aheadFields = _filter(this.searchFields, (i: any) => i.filter.type === 'ahead')
    this.liveSearchFilters && this.initLiveSearch()
  }

  ngAfterViewInit(): void {
    !this.pickerState && this.initPicker()
  }

  ngOnChanges(change: SimpleChanges): void {
    setTimeout(() => (!_get(change, 'show.firstChange') && !this.pickerState) && this.initPicker(), 0)
  }

  ngOnDestroy(): void {
    (this.pickers.length > 0) && this.pickers.forEach((picker: any) => picker.destroy())
  }

  trackByFn(index: number): number {
    return index
  }

  selectFilterValue(select: any): string {
    const selected = (select.options || []).find((item: any) => item.selected)
    return selected.key
  }

  initLiveSearch(): void {
    this.liveSearchKeys = Object.keys(this.liveSearchFilters)
    this.liveSearchKeys.forEach((key: string) => {
      this.liveSearchControls[key] = new FormControl()
      this.liveSearchControls[key].valueChanges
        .pipe(startWith<any>(''), debounceTime(500))
        .subscribe((val: string) => this.liveSearchFilters[key].handle(val)
          .subscribe((res: any) => this.liveSearchLists[key] = res,
            (err: any) => global.console.log(err)))
    })
  }

  addLiveSearchFilter(key: string, val: any): void {
    this.where[key] = val
    this.onFilter.emit()
  }

  initPicker(): void {
    this.froms && this.froms.forEach((input: any) => {
      const fromPicker = flatpickr(input.nativeElement,
        { disableMobile: true, enableTime: true, time_24hr: true })
      this.pickers.push(fromPicker)
    })
    this.tos && this.tos.forEach((input: any) => {
      const toPicker = flatpickr(input.nativeElement,
        { disableMobile: true, enableTime: true, time_24hr: true })
      this.pickers.push(toPicker)
    })
    this.pickerState = true
  }

  clearInput(key: string, point: string): void {
    delete this.where[key][point]
  }

  clearFilter(): void {
    for (const key in this.checkboxFilters) {
      this.checkboxFilters[key].value = false
    }
    _each(this.aheadFields, (item: any) => item['value'] = '')
    this.liveSearchFilters && this.liveSearchKeys.forEach((key: string) => this.liveSearchControls[key].reset())
    this.onClearFilter.emit()
  }

  onDateChanged(event: any, field: any, point: string): void {
    const { key } = field
    const time = +new Date(event)
    !event && (_get(this.where, key) && delete this.where[key][point])
    _isEmpty(this.where[key]) && event && (_assign(this.where, { [key]: { [point]: time } }))
    !_isEmpty(this.where[key]) && event && (_assign(this.where[key], { [point]: time }))
    this.onFilter.emit()
  }

  changeSelectFilter(event: any, select: any): void {
    const newValue: string = event.value
    select.options.forEach((item: any) => {
      if (item.key == newValue) {
        item.selected = true
        this.onFilter.emit(true)
      } else {
        item.selected = false
      }
    })
  }

  changeSelect(value: string, field: any): void {
    const { key } = field
    value === 'all' && delete this.where[key]
    value !== 'all' && (this.where[key] = value)
    this.onFilter.emit(true)
  }

  getCheckboxes(): string[] {
    return _keys(this.checkboxFilters)
  }

  changeCheckbox(key: any, value: any): void {
    this.checkboxFilters[key].value = value
    this.onFilter.emit(true)
  }

  onChangeAhead(key: string, value: string, field: any): void {
    field.value = value
    if (!value) { delete this.where[key] }
    this.onFilter.emit(true)
  }

  filter(): void {
    this.onFilter.emit(true)
  }

  debounceFilter(item: any, id: any): void {
    if (!Object.keys(this.observables).includes(id)) {
      this.observables[id] = new Subject<any>()
      this.observables[id].asObservable().pipe(debounceTime(500))
        .subscribe(() => this.onFilter.emit(true))
    }
    this.observables[id].next(item)
  }
}

<div class="wrapper" *ngIf="status || (connected != undefined && !connected)">
  <mat-progress-bar mode="indeterminate" color="warn"></mat-progress-bar>
  <div class="spinner-wrapper">
    <div class="app-loading">
      <h3 *ngIf="(connected != undefined && !connected)" class="text-center connecting-msg">
        Not connected! Please check your network connection!
      </h3>
      <!-- <div class="preload-logo"></div>
      <svg class="spinner" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
      </svg> -->
    </div>
  </div>
</div>
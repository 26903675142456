import { first } from 'rxjs/operators'
import { NotificationService } from '../notification-service/notification.service'
import { SocketIoService } from '../../services/socket-io.service'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ElementRef,
  ViewChild,
} from '@angular/core'
import {
  assign as _assign,
  cloneDeep as _cloneDeep,
  filter as _filter,
  first as _first,
  get as _get,
  includes as _includes,
  isEmpty as _isEmpty,
  keys as _keys,
  map as _map,
  mapValues as _mapValues,
  merge as _merge,
  omit as _omit,
  omitBy as _omitBy,
  pick as _pick,
  remove as _remove,
  isEqual as _isEqual,
  values as _values
} from 'lodash'
import { Observable } from 'rxjs'
import { Table } from './table'
import { ElasticQuery } from './elastic-query'
import { SpinnerService } from '../spinner/spinner.service'
import { Account } from '../../models/account.model'
import { IConfigNotification } from '../notification-service/share/i-config-notification'
import { AppHttpService } from '../../services/app-http.service'

@Component({
  selector: 'basic-table',
  templateUrl: 'basic-table.component.html',
  styleUrls: ['basic-table.component.scss'],
})

export class BasicTableComponent implements OnInit, OnChanges {
  @Input() tableData: Table
  @Input() refresh: Observable<any>
  @Input() pureMode: boolean
  @Output() initShow = new EventEmitter()
  @ViewChild('csvFileLink', { static: false }) csvFileLink: ElementRef

  searchFields: any[]
  data: any[]
  where = {}
  dataNotFound = false
  refreshTable = false
  limit = 0
  total = 0
  allSelect = false
  actions: any = {}
  actionKeys: string[]
  skip = 0
  onFilterTimeout: any
  prevFilter: any

  private sort: any = {}
  private sortFields: any[]
  private selectList: any = []

  constructor(
    private notify: NotificationService,
    private socket: SocketIoService,
    private httpService: AppHttpService,
    private spinner: SpinnerService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    changes['tableData'] && !changes['tableData'].firstChange
      && (this.tableData = changes['tableData'].currentValue, this.buildTable())
  }

  ngOnInit(): void {
    this.buildTable()
  }

  get isEmptyActionKeys(): boolean {
    return _isEmpty(this.tableData.actionList)
  }

  get isSearch(): boolean {
    return !_isEmpty(this.searchFields)
  }

  clearFilter(): void {
    _get(this.tableData, 'selectFilters', false) &&
      this.tableData.selectFilters
        .forEach((i: any) => i.options
          .forEach((e: any) => e.selected = e.key === 'all'))
    this.where = {}
    this.buildTable()
  }

  linkEntry(entry: any): void {
    this.tableData.rowLink && this.tableData.rowLink(entry)
  }

  initInputData(): void {
    this.limit = +this.tableData.limit || 25
    this.initActions()
    this.initSort()
    this.sortFields = this.tableData
      .cols
      .filter((col: any) => col.sort)
      .map((i: any) => i.key)

    this.searchFields = this.tableData
      .cols
      .filter((col: any) => col.search)
      .map((i: any) => ({ alias: i.alias, key: i.key, filter: i.search }))
  }

  onToggle(item: any): void {
    (this.selectList.some((i: any) => item === i))
      ? _remove(this.selectList, (i: any) => i === item) : this.selectList.push(item)
  }

  selectAll(): void {
    this.selectList.length = 0;
    (this.allSelect = !this.allSelect) && this.data.forEach((i: any) => (i.role !== 'admin' && this.selectList.push(i)))
  }

  deleteAll(): void {
    const filteredList = _get(this.selectList[0], 'role')
      ? _filter(this.selectList, (i: any) =>
        _get(i, 'employee.administrator')
          ? this.notify.add(<IConfigNotification>{
            type: 'error',
            title: 'You select ADMIN !',
            content: `${i.username} is administrator`
          })
          : i)
      : this.selectList
    const list = _map(filteredList, _get(this.tableData.deleteAll, 'keys')
      ? (e: any) => _pick(e, this.tableData.deleteAll.keys)
      : '_id')
    !_isEmpty(list) && this.tableData.deleteAll.handle(list)
  }

  markWeblium(value: boolean): void {
    const list = _filter(this.selectList, '_id')
    this.tableData.markWeblium.handle(_map(list, '_id'), value)
  }

  resolver(date: any): void {
    this.tableData.resolver.handle(this.selectList, date)
  }

  unresolver(): void {
    this.tableData.unresolver.handle(this.selectList)
  }

  doAction(index: any): void {
    this.tableData.actionList[index].handle && this.tableData.actionList[index].handle(this.selectList)
  }

  onFilter(value = false): void {
    this.skip = 0
    this.loadData(value)
  }

  onDataLoaded({ data, skip, total }: any): void {
    this.refresh && this.refresh.pipe(first()).subscribe(() => this.loadData(true))
    this.refreshTable = false
    this.data = data || []
    this.skip = skip || 0
    this.total = total || 0
    _isEmpty(this.data) && (this.dataNotFound = true)
    this.spinner.hide()
  }

  isShowTable(): boolean {
    return _get(this.tableData, 'cols') && !_isEmpty(this.data)
  }

  getThClasses(item: { key: string }): Object {
    return {
      sort: _includes(this.sortFields, item.key),
      isSorted: _first(_keys(this.sort)) === item.key,
      up: _first(_keys(this.sort)) === item.key && _first(_values(this.sort)) > 0,
      down: _first(_keys(this.sort)) === item.key && _first(_values(this.sort)) < 0
    }
  }

  onSort(col: any): void {
    if (col.sort) {
      const currentKey = _first(_keys(this.sort))
      this.sort = { [col.key]: col.key === currentKey ? (this.sort[currentKey] === 1 ? -1 : 1) : -1 }
      this.loadData(true)
    }
  }

  prev(): void {
    this.skip -= this.limit
    this.loadData(true)
  }

  next(): void {
    this.skip += this.limit
    this.loadData(true)
  }

  access(): boolean {
    return (Account.current && Account.current.canAccess('administrator admin artdirector')) || false
  }

  private buildTable(): void {
    this.initInputData()
    this.loadData()
  }

  private initSort(): void {
    let direction = 1
    let field = this.tableData.defaultSort || this.tableData.cols[0].key
    field[0] === '-' && (field = field.slice(1)) && (direction = -1)
    this.sort = { [field]: direction }
  }

  private prepareData(forceLoad = false): string | any {
    this.dataNotFound = false
    this.selectList.length = 0
    this.allSelect = false

    const { where: filter, sort, customQuery } = this.prepareQuery()
    if (!forceLoad && _isEqual(filter, this.prevFilter)) {
      return
    }
    this.prevFilter = _cloneDeep(filter)

    return _assign(
      _omit(
        this.tableData,
        [
          'liveSearchFilters',
          'customQuery',
          'select',
          'rowLink',
          'json',
          'limit',
          'defaultSort',
          'cols',
          'where',
          'sort',
          'actions',
          'tableType',
          'startFilter',
          'checkboxCol',
          'checkboxFilters',
          'selectFilters',
          'deleteAll',
          'markWeblium',
          'resolver',
          'unresolver',
          'exportFields',
          'actionList',
          'csv',
          'lazyScroll',
          'protocol'
        ]
      ), {
        customerId: this.tableData.customerId,
        id: this.tableData.id,
        query: {
          ...customQuery,
          sort,
          select: this.tableData.select || '',
          skip: this.skip,
          limit: this.limit,
          filter
        }
      })
  }

  private loadData(forceLoad = false): void {
    const data = this.prepareData(forceLoad)
    if (!data) {
      return
    }
    this.refreshTable = true
    this.spinner.show()
    const onData = this.tableData.protocol == 'http'
      ? this.httpService.postJSONStream(data)
      : this.socket.emit(data.action, data)

    onData.subscribe(
      (res: any) => (this.onDataLoaded(res), this.spinner.hide(), this.initShow.emit(), this.tableData.json &&
        typeof this.tableData.json === 'function' && this.tableData.json(res)),
      (error: any) => (this.handleError(error), this.initShow.emit(), this.spinner.hide())
    )

  }

  private handleError(err: any): void {
    this.notify.add(<IConfigNotification>{
      type: 'error',
      title: 'Error !',
      content: err.message || err.code || err
    })
    this.dataNotFound = true
    this.refreshTable = false
    this.spinner.hide()
    this.data = []
  }

  private initActions(): void {
    this.actions = this.tableData.actions || {}
    this.actions && (this.actionKeys = _keys(this.actions)
      .filter((action: any) => this.actions[action].roles
        ? Account.current.canAccess(this.actions[action].roles) : true))
  }

  private getFieldsByType(type = 'text'): any {
    return this.searchFields.filter((i: any) => i.filter.type === type).map((i: any) => i.key)
  }

  private prepareQuery(): any {
    switch (this.tableData.tableType) {
      case 'pq':
        return this.preparePostgreeQuery()
      case 'elastic':
        return this.prepareElasticQuery()
      default:
        return this.prepareMongoQuery()
    }
  }

  private prepareMongoQuery(): any {
    const textFields = this.getFieldsByType(),
      idFields = this.getFieldsByType('id')

    let where = _merge(
      _omitBy(this.where, _isEmpty),
      _mapValues(_pick(this.where, textFields), (i: any) => ({ $regex: i, $options: 'i' }))
    )

    let ids = null
    const id: string | any = _get(_pick(this.where, idFields), '_id')

    if (id) {
      ids = { _id: id }
      where = _omit(where, '_id')
    }

    const checkboxWhere = _mapValues(_pick(this.tableData.checkboxFilters,
      <any>_filter(
        _keys(this.tableData.checkboxFilters),
        (i: any) => this.tableData.checkboxFilters[i].value)), (value: any) => value.where
    )

    const selectWhere = (this.tableData.selectFilters || [])
      .map(
        (select: any) => {
          const [where] = select
            .options.filter((option: any) => option.selected).map((option: any) => option.where || {})
          return where
        }
      ).reduce((previous: Object, current: Object) => ({ ...previous, ...current }), {})

    return {
      where: {
        ...this.tableData.startFilter || {},
        ...selectWhere,
        ...checkboxWhere,
        ...where,
        ...ids
      },
      customQuery: this.tableData.customQuery,
      sort: this.sort
    }
  }

  downloadCsv(): void {
    const { query: { filter = {}, sort = {} } = {} } = this.prepareData(true)
    this.spinner.show()
    this.httpService.postStream({ action: this.tableData.action, query: { filter, csv: true, sort } })
      .subscribe((response: any) => {
        const blob: Blob = response
        const fileName = `${+Date.now()}.csv`
        const url = window.URL.createObjectURL(blob)
        this.csvFileLink.nativeElement.setAttribute('href', url)
        this.csvFileLink.nativeElement.setAttribute('download', fileName)
        this.csvFileLink.nativeElement.click()
        this.spinner.hide()
      },
        (error: any) => this.handleError(error))
  }

  private preparePostgreeQuery(): { where: any, sort: any } {
    const textFields = this.getFieldsByType()
    const where = _merge(_omitBy(this.where, _isEmpty), _mapValues(_pick(this.where, textFields), (i: any) => ({
      $like: `%${i}%`
    })))
    const sort = _keys(this.sort).map((i: any) => (this.sort[i] as number) > 0 ? i : `-${i}`)

    const checkboxWhere = _mapValues(_pick(this.tableData.checkboxFilters,
      <any>_filter(
        _keys(this.tableData.checkboxFilters),
        (i: any) => this.tableData.checkboxFilters[i].value)), (value: any) => value.where
    )

    return {
      where: {
        ...this.tableData.startFilter || {},
        ...checkboxWhere,
        ...where
      },
      sort
    }
  }

  private prepareElasticQuery(): any {
    const sort = this.sort,
      textFields = this.getFieldsByType(),
      aheadFields = this.getFieldsByType('ahead'),
      dateFields = this.getFieldsByType('date')

    const currentWhere = <{}>_omitBy(_pick(this.where, [...textFields, ...aheadFields]), _isEmpty)
    const dateWhere = _map(_cloneDeep(<{}>_omitBy(_pick(this.where, dateFields), _isEmpty)), (val: any, key: any) => {
      val.$gt && (val.gt = new Date(val.$gt), delete val.$gt)
      val.$lt && (val.lt = new Date(val.$lt), delete val.$lt)
      return { range: { [key]: val } }
    })

    const where = <ElasticQuery>_cloneDeep(this.tableData.startFilter || {})
    const defaultQuery = `msg.type: client`

    const { query } = currentWhere && Object.keys(currentWhere).reduce((acc: any, prev: any) =>
      ({ query: `${acc.query} AND ${prev}: ${acc.where[prev].replace('@', '\"@\"')}`, where: acc.where }),
      { where: currentWhere, query: defaultQuery })

    if (where.bool || query) {
      where.bool = {
        must: [..._get(where, 'bool.must', []), ...dateWhere, { query_string: { query } }]
      }
    }

    return { where, sort }
  }
}

import {
  NgModule,
  ModuleWithProviders
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { SpinnerComponent } from './spinner.component'
import { SpinnerService } from './spinner.service'
import { MatProgressBarModule } from '@angular/material/progress-bar'

@NgModule({
  imports: [CommonModule, MatProgressBarModule],
  providers: [SpinnerService],
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent]
})

export class SpinnerModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SpinnerModule,
      providers: [SpinnerService]
    }
  }
}

import { NavMenuService } from './menu/nav-menu.service'
import { CommonModule } from '@angular/common'
import { NavMenuComponent } from './menu'
import { NgModule } from '@angular/core'
import { SidebarComponent } from './sidebar.component'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatSidenavModule } from '@angular/material/sidenav'
import { NavMenuItemComponent } from './menu/components/menu-item'

const IMPORTS = [
  MatIconModule,
  CommonModule,
  MatSidenavModule,
  MatListModule
]

@NgModule({
  imports: [...IMPORTS],
  exports: [SidebarComponent],
  declarations: [SidebarComponent, NavMenuComponent, NavMenuItemComponent],
  providers: [NavMenuService]
})
export class SidebarModule { }

import { ResolverModule } from '../resolver/resolver.module'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatSelectModule } from '@angular/material/select'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { ClipboardModule } from 'ngx-clipboard'
import {
  AheadListPipe,
  TotalPagesPipe,
  CellPipe
} from './pipes'
import { BasicTableComponent } from './basic-table.component'
import { BasicTableRowComponent } from './components/row/basic-table-row.component'
import { BasicTableCellComponent } from './components/row/components/cell/basic-table-cell.component'
import { BasicTablePaginatorComponent } from './components/paginator/basic-table-paginator.component'
import { BasicTableFilterComponent } from './components/filter/basic-table-filter.component'
import { ScrollingModule } from '@angular/cdk/scrolling'

const PIPES = [
  TotalPagesPipe,
  AheadListPipe,
  CellPipe
]

const COMPONENTS = [
  BasicTableComponent,
  BasicTableRowComponent,
  BasicTableCellComponent,
  BasicTablePaginatorComponent,
  BasicTableFilterComponent
]

const IMPORTS = [
  ScrollingModule,
  ResolverModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatInputModule,
  MatSnackBarModule,
  ClipboardModule,
  MatMenuModule,
  MatButtonModule,
  MatIconModule,
  MatCheckboxModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  CommonModule
]

@NgModule({
  imports: [...IMPORTS],
  declarations: [...COMPONENTS, ...PIPES],
  exports: [...COMPONENTS]
})

export class BasicTableModule {
  static forRoot(): ModuleWithProviders<any> {
    return { ngModule: BasicTableModule }
  }
}

import { Router } from '@angular/router'
import { environment } from './../../../environments/environment'
import { Injectable } from '@angular/core'
import { Observable, from, defer, throwError, Subscription, of } from 'rxjs'
import SOCKET_ATTACH from '../../../socket-api'
import { catchError, tap, map, filter, takeWhile, first } from 'rxjs/operators'
import { User } from '../models/user.model'
import { Account } from '../models/account.model'
import { SpinnerService } from '../modules/spinner/spinner.service'

interface SocketRequest {
  action: string
  time: number
  data?: any
  response: any
  error?: boolean
}
interface SocketError {
  code: string
  message: string
  status: number
}

@Injectable({ providedIn: 'root' })
export class SocketIoService {
  connected = false
  user: User
  api: any
  subscribe$: Observable<any>
  logoutSource$: Observable<any>
  subscribers: { [key: string]: Observable<any> } = {}
  protected SOCKET_TIMEOUT = 120 * 1000

  constructor(private router: Router, private spinner: SpinnerService) {
    this.initSocket()

    this.logoutSource$ = defer(() => from(this.api.auth.logout())
      .pipe(
        map((result: any) => ({ ...result, action: 'auth.logout' })),
      ))
  }

  initSocket = (refreshToken = localStorage.getItem('refreshToken')): void => {
    this.api = window.api = SOCKET_ATTACH(environment.BO_API_URL, {
      timeout: this.SOCKET_TIMEOUT, socket: { transports: ['websocket', 'polling'] },
      throwErrorOnStatus: true,
      ...refreshToken && { refreshToken }
    })
    this.api.on('connect', () => (this.connected = true))
    this.subscribe$ = this.subscribeToNotifications()
    this.subscribeToRelogin()
  }

  subscribeToNotifications = (): Observable<any> => Observable.create((observer: any) =>
    this.api.on('notify', (data: any) => {
      // eslint-disable-next-line no-restricted-syntax
      environment.SHOW_SUBSCRIBES_LOG && global.console.info(`%csend2client:\n`, `color:orange`, { data })
      observer.next(data)
    }))

  subscribeToRelogin = (): void => this.api.on('login', ({ refreshToken, token }:
    { refreshToken: string, token: string }) => this.setTokens({ refreshToken, token }))

  setTokens = ({ refreshToken, token }: { refreshToken?: string, token?: string }): void => {
    refreshToken && localStorage.setItem('refreshToken', refreshToken)
    token && localStorage.setItem('token', token)
  }

  clearToken = (): void => {
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('token')
  }

  logToConsole = ({ action, time, data, response, error = false }: SocketRequest): void => {
    const duration = Date.now() - time
    // eslint-disable-next-line no-restricted-syntax
    global.console.info(`%c${action}: ${duration} ms.\n`, duration < 1000 && !error ? `color:green` : `color:red`,
      { request: { ...data }, response })
  }

  login = (data: any, time = Date.now()): Observable<any> => defer(() => from(this.api.auth.login({ ...data }))
    .pipe(
      catchError(error => {
        environment.SHOW_LOG && this.logToConsole({ response: error, time, data, action: 'auth.login', error: true })
        return throwError(error)
      }),
      tap(response => {
        environment.SHOW_LOG && this.logToConsole({ response, time, data, action: 'auth.login' })
        this.setTokens({ refreshToken: this.api.refreshToken, token: this.api.token })
        return response
      })
    )
  )

  handleSocketError = (error: SocketError,
    $source: Observable<any>,
    request: SocketRequest): Observable<any> => {
    environment.SHOW_LOG && this.logToConsole(request)

    switch (true) {
      case error.status == 401 || error.code == 'api.auth.unauthorized':
        this.clearToken()
        this.logout()
        return of({ code: error.code, status: error.status, message: error.message })
      case error.code == 'ECONCLOSED':
        return $source
      default:
        return throwError(error)
    }
  }

  clearAndNavigateToLogout = (): void => {
    this.spinner.hide()
    Account.current = null
    localStorage.removeItem('CURRENT_USER')
    this.clearToken()
    this.router.navigate(['/login'], {queryParamsHandling: 'preserve'})
  }

  logout = (time = Date.now()): Subscription => this.logoutSource$
    .pipe(tap(response => environment.SHOW_LOG && this.logToConsole({ response, time, action: response.action })))
    .subscribe(
      () => this.clearAndNavigateToLogout(),
      () => this.clearAndNavigateToLogout()
    )

  sendRequest = ({ action, data, time = Date.now() }:
    { action: string, data: any, time?: number }): Observable<any> =>
    defer(() => from(data ? this.api[action](data) : this.api[action]()))
      .pipe(
        tap(response => {
          environment.SHOW_LOG && this.logToConsole({ response, time, data, action })
        }),
        map((result: any) => result.data && !result.code
          ? ({ ...result, action })
          : throwError({
            code: result.code || 'api.data.empty',
            message: 'Response from server is empty', status: 204
          })),
        catchError((error, $source) => this.handleSocketError(error, $source, {
          response: error, time, data, action,
          error: true
        }))
      )

  emit = (action: string, data?: any, spinner = false,
    refreshToken = localStorage.getItem('refreshToken')): Observable<any> => {
    spinner && this.spinner.show()
    !refreshToken && this.logout()

    return (!refreshToken
      ? of([]).pipe(
        map(() => {
          this.clearAndNavigateToLogout()
          return throwError({ code: 'api.auth.need_relogin', message: 'Need relogin', status: 401 })
        }))
      : this.sendRequest({ action, data })).pipe(
        tap(() => spinner && this.spinner.hide()),
        first(),
        takeWhile((response: any) => refreshToken && response.status != 401)
      )
  }

  on = (action: string, entity?: string): Observable<any> => this.subscribers[action] || (
    this.subscribers[action] = Observable.create((observer: any) => (
      this.subscribe$.pipe(
        filter(result => (entity && ('' + entity).includes('' + result.entity))
          && ('' + action).includes('' + (result.action || result.message))
          || ('' + action).includes('' + (result.action || result.message))
        )
      ).subscribe(result => observer.next(result)),
      this.subscribers[action])
    )
  )
}

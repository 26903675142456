import { SocketIoService } from './socket-io.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable()
export class StatisticsService {
  constructor(private socket: SocketIoService) { }

  getEntityStatistics(data: { type: string, period: string, tzOffset: number }): Observable<any> {
    return this.socket.emit('offDashboard.entityStatistic', { data })
      .pipe(map((res: any) => res.data))
  }

  getConnectionsStatistic(): Observable<any> {
    return this.socket.emit('offDashboard.connectionsStatistic')
      .pipe(map((res: any) => res.data))
  }

  getDashboardStatistics(): Observable<any> {
    return this.socket.emit('offDashboard.statistic')
      .pipe(map((res: any) => res.data))
  }

  getSalesStatistics(): Observable<any> {
    return this.socket.emit('offDashboard.salesStatistic')
      .pipe(map((res: any) => res.data))
  }
}

import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { User } from '../models/user.model'
import { SocketIoService } from './socket-io.service'
import { map } from 'rxjs/operators'

@Injectable()
export class UserService {
  constructor(private socket: SocketIoService) { }

  get(_id?: string): Observable<User> {
    return this.socket.emit('offUser.get', { _id }).pipe(
      map((response: any) => <User>(User.fromJSON(response.data)))
    )
  }

  getFromChargebee(_id: string): Observable<any> {
    return this.socket.emit('offUser.getFromChargebee', { _id }).pipe(map((response: any) => response.data))
  }

  customerActivity(query: any): Observable<any> {
    return this.socket.emit('offUser.customerActivity', { query }).pipe(map((response: any) => response.data))
  }

  remove(_id: string): Observable<any> {
    return this.socket.emit('offUser.remove', { _id })
  }

  setUsername(_id: string, email: string): Observable<any> {
    return this.socket.emit('offUser.setUsername', {
      data: { username: email },
      _id
    }).pipe(map((response: any) => response.data))
  }

  removeMany(_id: string[]): Observable<any> {
    return this.socket.emit('offUser.removeMany', { query: { filter: { _id: { $in: _id } } } })
  }

  updateProfile(data: any): Observable<any> {
    return this.socket.emit('offUser.updateProfile', { data })
  }

  update(_id: string, data: any): Observable<any> {
    return this.socket.emit('offUser.update', { _id, data })
      .pipe(map((response: any) => response.data))
  }

  updateComment(_id: string, data: any): Observable<any> {
    return this.socket.emit('offUser.updateComment', { _id, data })
      .pipe(map((response: any) => response.data))
  }

  removeComment(_id: string): Observable<any> {
    return this.socket.emit('offUser.removeComment', { _id })
      .pipe(map((response: any) => response.data))
  }

  add(data: any): Observable<User> {
    return this.socket.emit('offUser.add', { data })
      .pipe(map((response: any) => <User>(User.fromJSON(response.data))))
  }

  changeProfilePassword(data: any): Observable<any> {
    return this.socket.emit('offUser.changeProfilePassword', { data })
  }

  setPassword(_id: string, data: any): Observable<any> {
    return this.socket.emit('offUser.setPassword', { data, _id })
  }

  block(_id: string): Observable<any> {
    return this.socket.emit('offUser.block', { _id })
  }

  suggestWebsitePlan(_id: string): Observable<any> {
    return this.socket.emit('offUser.suggestWebsitePlan', { _id })
      .pipe(map((response: any) => response.data))
  }

  websitePlans(_id: string): Observable<any> {
    return this.socket.emit('offUser.websitePlans', { _id })
      .pipe(map((response: any) => response.data))
  }

  unblock(_id: string): Observable<any> {
    return this.socket.emit('offUser.unblock', { _id })
  }

  byEmail(email: string): Observable<any> {
    return this.socket.emit('offUser.byEmail', { email })
      .pipe(map((response: any) => response.data))
  }

  registeredByCountry(): Observable<{ _id: string, count: number }[]> {
    return this.socket.emit('offUser.registeredByCountry')
      .pipe(map((response: any) => response.data))
  }

  listCustomers($regex: string, filter?: any): Observable<any> {
    const find = { $regex, $options: 'i' }
    return this.socket.emit('offUser.list', {
      query: {
        limit: 5,
        filter: {
          role: { $eq: 'customer' },
          ...filter ? filter: {},
          $or: [
            { username: find },
            { name: find },
            { lastName: find }
          ]
        }
      }
    })
      .pipe(map((response: any) => response.data))
  }

  assignBitrix(id: string): Observable<any> {
    return this.socket.emit('offUser.assignToBitrix', { id })
      .pipe(map((response: any) => response.data))
  }

  unAssignBitrix(id: string): Observable<any> {
    return this.socket.emit('offUser.unAssignFromBitrix', { id }).pipe(map((response: any) => response.data))
  }

  singlePayment(query: any): Observable<any> {
    return this.socket.emit('offUser.singlePayment', { query })
      .pipe(map((response: any) => response.data))
  }

  removeCustomDomain(_id: string): Observable<any> {
    return this.socket.emit('offUser.removeCustomDomain', { _id })
  }

  removeStructureCustomDomain(_id: string): Observable<any> {
    return this.socket.emit('offUser.removeStructureCustomDomain', { _id })
  }

  decrementPromisedDomains(_id: string, subscription: string): Observable<any> {
    return this.socket.emit('offUser.decrementPromisedDomains', { _id, subscription }).pipe(map((response: any) => response.data))
  }
}

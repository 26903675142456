import {
  Component,
  OnInit,
  Input
} from '@angular/core'
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations'
import { NotificationService } from '../../notification.service'
import { IConfigNotification } from '../../share/i-config-notification'

const HIDETIME = 300

@Component({
  selector: 'notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['notification.component.scss'],
  animations: [
    trigger('enterLeave', [
      state('fromRight', style({ opacity: 1, transform: 'translateX(0%)' })),
      transition('* => fromRight', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate('400ms ease-in-out')
      ]),
      state('fromRightOut', style({ opacity: 0, transform: 'translateX(100%)' })),
      transition('fromRight => fromRightOut', [
        style({ opacity: 1, transform: 'translateX(0%)' }),
        animate(HIDETIME + 'ms ease-in-out')
      ])
    ])
  ]
})

export class NotificationComponent implements OnInit {

  @Input() config: IConfigNotification
  @Input() index: any

  private progressWidth = 0
  private timer: any
  private step: number

  constructor(private notificationService: NotificationService) { }

  ngOnInit() {
    this.start(this.config.duration)
  }

  hide(state: 'fromRightOut'): void {
    this.config.state = state
    this.stop()
    setTimeout(() =>
      this.notificationService.remove(this.index),
      HIDETIME + 50
    )
  }

  start(duration: number): void {
    if (this.progressWidth !== 100) {
      if (!this.step) { this.step = duration / 100 }
      this.timer = setInterval(() => {
        this.timeBraker()
      }, this.step)
    }
  }

  timeBraker(): void {
    (this.progressWidth === 100) ? this.hide('fromRightOut') : this.progressWidth++
  }

  stop(): void {
    clearInterval(this.timer)
  }

}

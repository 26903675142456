import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterContentInit
} from '@angular/core'
import { Account } from '../../../share/models/account.model'
import { style, animate, transition, trigger } from '@angular/animations'
import { environment } from '../../../../environments/environment'

@Component({
  selector: 'ba-card',
  styleUrls: ['ba-card.scss'],
  templateUrl: 'ba-card.html',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger(
      'showJson',
      [
        transition(
          ':enter', [
            style({ opacity: '0' }),
            animate('200ms', style({ opacity: '1' }))
          ]
        ),
        transition(
          ':leave', [
            style({ opacity: '1' }),
            animate('200ms', style({ opacity: '0' })
            )]
        )
      ]
    )
  ]
})

export class BaCardComponent implements OnInit, AfterContentInit {
  @Input() cardTitle: String
  @Input() baCardClass: String
  @Input() json: any
  @Input() jsonAccess: boolean
  @Input() link: any
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>()
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>()

  account: Account
  showJson = false
  isCopied = false
  stringifiedJSON: string

  constructor() {}

  ngOnInit(): void {
    this.account = Account.current
    !this.jsonAccess && (this.jsonAccess = this.account.isAdmin())
  }

  ngAfterContentInit(): void {
    this.stringifiedJSON = JSON.stringify(this.json, null, 2)
  }

  close(): void {
    this.onClose.emit()
  }

  edit(): void {
    this.onEdit.emit()
  }

  copied() {
    this.isCopied = true
    setTimeout(() => this.isCopied = false, 1000)
  }

  linkBuilder(link: string[]): string {
    return link && link.reduce((acc: string, val: string, i: number) =>
      acc + (i > 0 && `/${val}` || val), `${environment.BO_URL}/#`)
  }

  get copyMsg(): string {
    return this.isCopied ? 'COPIED!' : 'COPY CODE'
  }
}

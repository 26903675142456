import { NotificationService } from '../modules/notification-service/notification.service'
import { Injectable } from '@angular/core'
import { IConfigNotification } from '../modules/notification-service/share/i-config-notification'

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  constructor(private notify: NotificationService) { }

  notifyError(err: any, duration = 3000): void {
    this.notify.add(<IConfigNotification>{
      type: 'error',
      title: 'Error!',
      content: err.error_msg || err.message || err.code || err,
      duration
    })
  }
}

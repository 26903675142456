import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'basic-table-paginator',
  templateUrl: 'basic-table-paginator.component.html',
  styleUrls: ['basic-table-paginator.component.scss'],
})
export class BasicTablePaginatorComponent {
  @Input() skip: number
  @Input() total: number
  @Input() limit: number

  @Output() onPrev: EventEmitter<any> = new EventEmitter<any>()
  @Output() onNext: EventEmitter<any> = new EventEmitter<any>()


  constructor() {
  }

  get showNext(): boolean {
    return this.skip + this.limit < this.total
  }

  prev() {
    this.onPrev.emit()
  }

  next() {
    this.onNext.emit()
  }
}

<div class="row">
  <div class="col-sm-12">
    <div class="container">
      <div class="b-b">
        <button mat-fab
                class="btn-file"
                color="primary">
          <mat-icon aria-label="Browse">attach_file</mat-icon>
          <label for="file-upload" class="file-upload"></label>
          <input type="file"
                 id="file-upload"
                 ng2FileSelect
                 [uploader]="uploader"
                 multiple
                 accepts="image/*"/>
        </button>
      </div>
    </div>
    <div class="container">
      <ba-card>
        <div class="row">
          <div class="col-sm-12">
            <div
              ng2FileDrop
              [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
              (fileOver)="fileOverBase($event)"
              [uploader]="uploader"
              class="well my-drop-zone top-buffer">
              <h3 class="text-center">Drop files here</h3>
            </div>
          </div>
        </div>
      </ba-card>
    </div>
    <div class="container" *ngIf="uploader?.queue?.length" [@rightSlide]>
      <ba-card class="queue-table">
        <div class="row">
          <div class="col-sm-12">
            <h3>Upload queue</h3>
            <p>Queue length: {{ uploader?.queue?.length }}</p>
          </div>
        </div>
        <div class="row queue-table-row" [ngClass]="{ 'withoutScroll': uploader?.queue?.length <= 7 }">
          <div class="col-sm-12">
            <table class="table table-striped">
              <thead>
              <tr>
                <th width="50%">Name</th>
                <th>Size</th>
                <th>Progress</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of uploader?.queue">
                <td><strong>{{ item?.file?.name }}</strong></td>
                <td *ngIf="uploader?.options?.isHTML5"
                    class="text-center"
                    nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                <td *ngIf="uploader?.options?.isHTML5">
                  <mat-progress-bar
                    class="progress-td"
                    color="primary"
                    mode="determinate"
                    [value]="item?.progress">
                  </mat-progress-bar>
                </td>
                <td class="text-center status-cell">
                  <mat-icon *ngIf="item.isUploading"
                            class="loop"
                            color="primary"
                            aria-label="Upload">sync</mat-icon>
                            
                  <mat-icon *ngIf="item.isSuccess"
                            class="s-done"
                            aria-label="Done">done</mat-icon>

                  <mat-icon *ngIf="item.isCancel && !item.isError"
                            class="s-error"
                            matTooltip="Canceled"
                            matTooltipPosition="above"
                            aria-label="Cancel">cancel</mat-icon>

                  <mat-icon *ngIf="(!item.isCancel && item.isError) || item.isError"
                            class="s-error"
                            matTooltip="Already Exist"
                            matTooltipPosition="above"
                            aria-label="Cancel">error</mat-icon>
                </td>
                <td class="text-center btn-container" nowrap>
                  <button mat-stroked-button
                          class="btn-upload"
                          type="button"
                          color="primary"
                          class="m-r"
                          (click)="checkAndUpload(item)"
                          [disabled]="item.isReady || item.isUploading || item.isSuccess">
                    Upload
                  </button>
                  <button mat-stroked-button
                          type="button"
                          color="warn"
                          (click)="item.remove()">
                    Remove
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ba-card>
    </div>
    <div class="container" *ngIf="uploader?.queue?.length" [@leftSlide]>
      <div class="queue-container">
        <mat-progress-bar
          color="primary"
          mode="determinate"
          [value]="uploader?.progress">
        </mat-progress-bar>
        <ba-card>
          <div class="row top-buffer">
            <div class="col-sm-12 text-center">
              <button mat-raised-button
                      class="btn-upload-all m-r"
                      type="button"
                      (click)="uploadAll()"
                      [disabled]="!uploader.getNotUploadedItems().length"
                      color="primary">
                Upload all
              </button>
              <button mat-raised-button
                      type="button"
                      (click)="uploader.clearQueue()"
                      [disabled]="!uploader.queue.length"
                      color="warn">
                Remove all
              </button>
            </div>
          </div>
        </ba-card>
      </div>
    </div>
  </div>
</div>

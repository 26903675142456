import { ANIMATION } from '../../../../animations'
import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output
} from '@angular/core'
import { GalleryService } from '../../gallery.service'
import { Account } from '../../../../models/account.model'

const leftSlideState = ANIMATION.leftSlideState

@Component({
  selector: 'gallery-list',
  templateUrl: 'gallery-list.component.html',
  styles: [`
    .upload-container{
      transform: translateX(-200%);
    }
  `],
  animations: [leftSlideState]
})

export class GalleryListComponent implements OnInit {
  @Input() moduleConfig: any
  @Output() showDetailsStream = new EventEmitter<string>()
  account: Account
  animStatus: string

  constructor(private galleryService: GalleryService) { }

  ngOnInit(): void {
    this.account = Account.current
    this.subscribeToShow()
  }

  initAnim(): void {
    this.animStatus = 'show'
  }

  private subscribeToShow(): void {
    this.galleryService
      .resourceShowSource$
      .subscribe((id: any) => this.showResource(id), error => global.console.error(error))
  }

  private showResource(resourceId: string): void {
    this.showDetailsStream.emit(resourceId)
  }
}

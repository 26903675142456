import { get as _get } from 'lodash'

export class HandleError {
  static readonly serverCodes = {
    'api.user.exists': 'User is exist',
    'api.user.oldpassword_error': 'Old password is wrong',
    'api.processoraccount.exists': 'Processor account is exist',
    'api.customer.invalid_concat_id': 'Invalid ID',
    'api.auth.invalid_username_or_password': 'Invalid username or password',
    'api.customer.notfound': 'Customer not found',
    'api.customer.key_may_be_expired': 'Time for access expired'
  }

  static getErrorMessage(error: any): any {
    return _get(error, 'message') || _get(error, 'code') || 'Something wrong!'
  }

  public static getMsgByCode(code: any): string {
    return _get(HandleError.serverCodes, code) || code
  }

  public static getValidationMsg(err: any): string {
    const [, field] = err.dataPath.match(/(\w+)$/)
    const message = _get(err, 'message', 'Not valid')
    return `${field} ${message}`
  }
}

import { map } from 'rxjs/operators'
import { SocketIoService } from '../../share/services/socket-io.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable()
export class ChargebeePlanService {
  constructor(private socket: SocketIoService) { }

  list(params: any): Observable<any> {
    return this.socket.emit('offChargebeePlan.list', params).pipe(map((response: any) => response.data))
  }

  get(_id: string, checkRegisteredClients?: boolean): Observable<any> {
    return this.socket.emit('offChargebeePlan.get', {
      _id, ...checkRegisteredClients ? { checkRegisteredClients: true } : {}
    }).pipe(map((response: any) => response.data))
  }

  update(_id: string, data: any): Observable<any> {
    return this.socket.emit('offChargebeePlan.update', { _id, data }).pipe(map((response: any) => response.data))
  }

  notifyApplication(id: string, organizationPlanId: string, websitePlanId: string, client: boolean): Observable<any> {
    return this.socket.emit('offChargebeePlan.notifyApplication', { id, organizationPlanId, websitePlanId, client})
      .pipe(map((response: any) => response.data))
  }
}

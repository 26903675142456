import { NgModule } from '@angular/core'
import { MapChartComponent } from './map-chart.component'
import { MapChartThemeService } from './map-chart-theme.service'
import { BaThemePreloader } from '../../../theme/services'

@NgModule({
  exports: [MapChartComponent],
  declarations: [MapChartComponent],
  providers: [MapChartThemeService, BaThemePreloader],
})

export class MapChartModule {
}

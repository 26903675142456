import { Component, Input } from '@angular/core'
import { SpinnerService } from './spinner.service'

@Component({
  selector: 'spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.scss']
})

export class SpinnerComponent {

  @Input() status = true
  @Input() connected = false

  constructor(private spinner: SpinnerService) {
    this.subscribeEvents()
  }

  private subscribeEvents(): void {
    this.spinner.actionStream$
      .subscribe(
        (entry: any) => entry ? this.show() : this.hide(),
        (error: any) => this.handleError(error)
      )
  }

  private show(): void {
    this.status = true
  }

  private hide(): void {
    this.status = false
  }

  private handleError(err: any): void { global.console.log(err) }
}

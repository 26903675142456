import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ConfirmDialogComponent } from './confirm-dialog.component'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { NgaModule } from '../../../theme/nga.module'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { FormsModule } from '@angular/forms'

const IMPORTS = [
  CommonModule,
  FormsModule,
  MatDialogModule,
  MatButtonModule,
  MatSlideToggleModule,
  NgaModule
]

@NgModule({
    imports: [...IMPORTS],
    declarations: [ConfirmDialogComponent],
    exports: [ConfirmDialogComponent]
})
export class ConfirmDialogModule { }

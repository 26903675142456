import { IDomainJson } from '../interfaces/domain'
import * as moment from 'moment'

export enum PingCategory {
  Ok = 'ok',
  Exception = 'exception',
  InvalidIp = 'error.invalidIp',
  WebsiteNotFound = 'error.websiteNotFound',
  DomainGet = 'error.domainGet',
  BucketGet = 'error.bucketGet',
  HtmlContent = 'error.htmlContent',
  SubdomainGet = 'error.subdomainGet',
  SubdomainRedirect = 'error.subdomainRedirect',
  RedirectToOther = 'error.redirectToOther',
  WrongNS = 'error.wrongNs',
  Expired = 'warning.expired',
  Suspended = 'warning.suspended',
  Maintenance = 'warning.maintenance',
  HasNoRender = 'warning.hasNoRender'
}

export class Domain {
  emails?: number
  currentNS?: any[]
  subscription?: any
  whois?: string
  ping?: {
    category: PingCategory,
    message: string,
    detectedAt: string,
    checkedAt: string,
    history: [
      {
        _id: string,
        category: PingCategory,
        message: string,
        detectedAt: string,
        checkedAt: string,
        resolved?: {
          at: string,
          by: string
        }
      }
    ]
    resolved?: {
      at: string,
      by: string
    }
  }
  _id: string
  name: string
  state: string
  website?: any
  zone?: string
  isPrimary?: boolean
  autorenew?: boolean
  ipConnected?: boolean
  enable_records_edit?: boolean
  provider?: {
    name?: string
    manualRegistered?: boolean
    info?: any
    contactSet?: any
  }
  owner: any
  createdAt: any
  updatedAt: any
  options: any
  certificate?: {
    wild?: boolean,
    key?: string,
    crt?: string,
  }

  static fromJSON(json: IDomainJson): Domain {
    const domain = Object.create(Domain.prototype)
    return Object.assign(domain, json, {
      createdAt: moment(json.createdAt),
      updatedAt: moment(json.updatedAt)
    })
  }

  static reviver(key: string, value: any): any {
    return key === '' ? Domain.fromJSON(value) : value
  }

  constructor() {
  }

  toJSON(): IDomainJson {
    return Object.assign({}, this, {
      createdAt: this.createdAt.format('YYYY-MM-DD HH:mm:ss'),
      updatedAt: this.createdAt.format('YYYY-MM-DD HH:mm:ss')
    })
  }
}

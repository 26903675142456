import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'centsToUsd'
})

export class CentsToUsdPipe implements PipeTransform {
  transform(cents: number): any {
    return (cents / 100).toFixed(2)
  }
}

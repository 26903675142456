import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'totalPages'
})

export class TotalPagesPipe implements PipeTransform {
  transform(totalItems: number, limit: number): number {
    return Math.ceil(totalItems / limit)
  }
}

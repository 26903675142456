import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'aheadList' })
export class AheadListPipe implements PipeTransform {
  transform(list: any[], value: any): any[] {
    if (value && value !== '') {
      return list.filter((item: any) =>
        item.value && new RegExp(value.toLowerCase()).test(item.value.toLowerCase()))
    } else {
      return list
    }
  }
}

import { UserService } from './../../services/user.service'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatRadioModule } from '@angular/material/radio'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgaModule } from '../../../theme/nga.module'
import { ListChangerComponent } from './components'
import { WebsiteService } from '../../services/website.service'
import { OrganizationService } from '../../services/organization.service'
import { CommonModule } from '@angular/common'
import { ConfirmModule } from '../confirm-btns'
import { NgModule } from '@angular/core'
import { DomainService } from '../../services'
import { MatSelectModule } from '@angular/material/select'
import { ChargebeePlanService } from '../../services/chargebeeplan.service'

const IMPORTS = [
  MatRadioModule,
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatInputModule,
  MatSelectModule,
  MatSlideToggleModule,
  NgaModule,
  ConfirmModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule
]

const RESOLVERS = [WebsiteService, DomainService, UserService, ChargebeePlanService, OrganizationService]

@NgModule({
  imports: IMPORTS,
  providers: RESOLVERS,
  declarations: [ListChangerComponent],
  exports: [ListChangerComponent]
})

export class ListChangerModule { }

import { Component, Input } from '@angular/core'
import { FileUploader, FileItem } from 'ng2-file-upload'
import { GalleryService } from '../../../../gallery.service'
import { SpinnerService } from '../../../../../../../share/modules/spinner/spinner.service'
import { reduce as _reduce, isEmpty as _isEmpty } from 'lodash'
import { environment } from '../../../../../../../../environments/environment'
import { ANIMATION } from '../../../../../../animations'
import { SocketIoService, ErrorHandlerService } from '../../../../../../services'

const URL = 'api/weblium/resource'
const rightSlide = ANIMATION.rightSlide, leftSlide = ANIMATION.leftSlide

@Component({
  selector: 'gallery-uploader',
  templateUrl: 'gallery-uploader.component.html',
  styleUrls: ['gallery-uploader.component.scss'],
  animations: [rightSlide, leftSlide]
})

export class GalleryUploaderComponent {

  @Input() uploadConfig: any
  itemErrors = {}


  hasBaseDropZoneOver = false
  fileIcons: { index: number, value: string }[]
  replaceFiles = false
  confirmReplace = false
  uploader: FileUploader

  constructor(
    private handler: ErrorHandlerService,
    private spinner: SpinnerService,
    private socket: SocketIoService,
    private galleryService: GalleryService,
  ) {
    const initUploader = (token: string) => this.uploader = new FileUploader({
      url: `${environment.BO_API_URL}/${URL}`,
      authToken: `Bearer ${token}`,
    })
    this.socket.api.token && initUploader(this.socket.api.token)
    !this.socket.api.token && this.socket.api.on('connect', () => initUploader(this.socket.api.token))
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e
  }

  uploadAll(): void {

    const items = this.uploader
      .getNotUploadedItems()
      .filter((item: any) => !item.isUploading)

    if (!items.length) {
      return
    }
    this.spinner.show()
    _reduce(items, (promise: any, item: any) => promise
      .then((data: any) => new Promise((resolve: any, reject: any) => this.galleryService
        .getFileByName(this.uploadConfig.service.getFileByNameAction,
          item.file.name,
          this.uploadConfig.service.owner)
        .subscribe(
          (response: any) => {
            if (_isEmpty(response.data)) {
              item._prepareToUploading()
              data.push(item)
            } else {
              item._onError('', null, {})
            }
            resolve(data)
          },
          error => reject(error)
        )
      )), Promise.resolve([]))
      .then((data: any) => (!_isEmpty(data) && this.upload(data[0]), this.spinner.hide()))
      .catch((e: any) => this.handleError(e))
  }

  checkAndUpload(item: FileItem): void {
    this.spinner.show()
    this.galleryService
      .getFileByName(this.uploadConfig.service.getFileByNameAction,
        item.file.name,
        this.uploadConfig.service.owner)
      .subscribe(
        (response: any) => this.upload(item, !_isEmpty(response.data)),
        (error: any) => this.handleError(error)
      )
  }

  private upload(item: FileItem, isExist = false): void {
    this.spinner.hide()
    !isExist && item.upload()
    isExist && (this.itemErrors = {
      ...this.itemErrors,
      ...{ [`${item.file.size}-${item.file.name}`]: 'Already exist' }
    })
    isExist && (item.isError = true)
  }

  private handleError(error: any): void {
    this.spinner.hide()
    this.handler.notifyError(error)
  }
}

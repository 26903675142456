import { map } from 'rxjs/operators'
import { SocketIoService } from './socket-io.service'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { Domain } from '../models/domain.model'
import { ZoneInfo } from '../../pages/domains/share/zone-info'

@Injectable()
export class DomainService {
  constructor(private socket: SocketIoService) { }

  domainStream = new Subject<any>()
  domainStream$ = this.domainStream.asObservable()

  transfer({
    name, secretCode, owner, provider = 'ukrnames'
  }: { name: string, secretCode: string, owner: string, provider?: string }): Observable<any> {
    return this.socket.emit('offDomain.transfer', { name, secretCode, owner, provider })
      .pipe(map((res: any) => res.data))
  }

  transferToUser(data: { id: string, toUser: string }): Observable<any> {
    return this.socket.emit('offDomain.transferToUser', data)
  }

  transferCodeRequest(_id: string): Observable<any> {
    return this.socket.emit('offDomain.transferCodeRequest', { _id })
      .pipe(map((res: any) => res.data))
  }

  setProvider({ id, provider }: { id: string, provider: string }): Observable<any> {
    return this.socket.emit('offDomain.setProvider', { id, data: { provider } })
      .pipe(map((res: any) => res.data))
  }

  zoneRegister(name: string): Observable<any> {
    return this.socket.emit('offDomain.zoneRegister', { name })
      .pipe(map((res: any) => res.data))
  }

  checkDomain(_id: string): Observable<any> {
    return this.socket.emit('offDomain.checkDomain', { _id })
      .pipe(map((res: any) => res.data))
  }

  zoneRecordUpdate({ name, id, type, record, host, priority, weight, port }
    : { name: string, id: string, type: string, record: string, host?: string, priority?: number, weight?: number, port?: number  })
    : Observable<any> {
    return this.socket.emit('offDomain.zoneRecordUpdate', { name, id, data: { type, record, host, priority, weight, port } })
      .pipe(map((res: any) => res.data))
  }

  zoneRecordAdd({ name, type, record, host, priority, weight, port }
    : { name: string, type: string, record: string, host?: string, priority?: number, weight?: number, port?: number })
    : Observable<any> {
    return this.socket.emit('offDomain.zoneRecordAdd', { name, data: { type, record, host, priority, weight, port} })
      .pipe(map((res: any) => res.data))
  }

  zoneRecordRemove({ name, id }: { name: string, id: string }): Observable<any> {
    return this.socket.emit('offDomain.zoneRecordRemove', { name, id })
      .pipe(map((res: any) => res.data))
  }

  zoneInfo(name: string): Observable<ZoneInfo> {
    return this.socket.emit('offDomain.zoneInfo', { name })
      .pipe(map((res: any) => res.data))
  }

  suggestPromised(name: string): Observable<Domain> {
    return this.socket.emit('offDomain.suggestPromised', { name })
      .pipe(map((res: any) => res.data))
  }

  register(name: string, data: Object = {}): Observable<any> {
    return this.socket.emit('offDomain.register', { name, data })
      .pipe(map((res: any) => res.data))
  }

  list(data: any): Observable<any> {
    return this.socket.emit('offDomain.list', data)
  }

  get(id: string): Observable<Domain> {
    return this.socket.emit('offDomain.get', { id })
      .pipe(map((res: any) => res.data))
  }

  disconnect(_id: string): Observable<any> {
    return this.socket.emit('offDomain.disconnect', { _id })
  }

  remove(id: string): Observable<any> {
    return this.socket.emit('offDomain.remove', { id })
      .pipe(map((res: any) => res.data))
  }

  renew(id: string): Observable<any> {
    return this.socket.emit('offDomain.renew', { id })
      .pipe(map((res: any) => res.data))
  }

  providerInfo(name: string): Observable<any> {
    return this.socket.emit('offDomain.providerInfo', { name })
      .pipe(map((res: any) => res.data))
  }

  balance(): Observable<{ balance: number, hold_balance: number }> {
    return this.socket.emit('offDomain.balance').pipe(map((res: any) => res.data))
  }

  save(data: {
    name: string, website?: string, registerDNS?: boolean, owner?: string, enable_records_edit?: boolean
  }): Observable<any> {
    return this.socket.emit('offDomain.save', { data: { registerDNS: false, ...data } })
  }

  removeMany(list: string[]): Observable<any> {
    return this.socket.emit('offDomain.removeMany', {
      data: {
        _id: {
          $in: list
        }
      }
    }).pipe(map((res: any) => res.data))
  }

  setPrimary(_id: string): Observable<any> {
    return this.socket.emit('offDomain.setPrimary', { _id })
  }
}

import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class ThemeService {
  themeStream = new Subject<any>()
  themeStream$ = this.themeStream.asObservable()

  constructor() { }

  changeTheme(theme: string, _id: string): void {
    this.themeStream.next({ theme, _id })
  }
}

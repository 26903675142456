<div class="card {{baCardClass || ''}}" baCardBlur zoom-in>
  <div *ngIf="json && jsonAccess || cardTitle" class="card-header clearfix">

    <h3 *ngIf="cardTitle" class="card-title">
      <a [routerLink]="link" *ngIf="link; else txt">{{cardTitle}}</a>
      <ng-template #txt>
        {{cardTitle}}
      </ng-template>
    </h3>

    <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="json && jsonAccess">
      <mat-icon class="card-btn">more_vert</mat-icon>
    </button>

    <button mat-icon-button class="btn-close" (click)="close()">
      <mat-icon class="card-btn">close</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngIf="json && jsonAccess" type="button" (click)="showJson = !showJson">
        <mat-icon>code</mat-icon>
        <span>{{showJson? 'Hide':'Show' }} JSON</span>
      </button>

      <a [routerLink]="link" target="_blank" *ngIf="link">
        <button mat-menu-item type="button">
          <mat-icon>open_in_new</mat-icon>
          <span>Open in tab</span>
        </button>
      </a>

      <button mat-menu-item type="button" ngxClipboard (cbOnSuccess)="copied()" [cbContent]="linkBuilder(link)" *ngIf="link">
        <mat-icon *ngIf="!isCopied; else copy">content_copy</mat-icon>
        <ng-template #copy>
          <mat-icon color="primary">done</mat-icon>
        </ng-template>
        <span>Copy link</span>
      </button>
    </mat-menu>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="row" *ngIf="showJson" [@showJson]="showJson">
      <div class="col-sm-12">
        <div class="inner-json">
          <button mat-icon-button matTooltip="Copy JSON" matTooltipPosition="above" *ngIf="stringifiedJSON" class="pull-right" type="button"
            ngxClipboard (cbOnSuccess)="copied()" [cbContent]="stringifiedJSON">
            <mat-icon *ngIf="!isCopied; else copy" aria-label="Copy JSON">content_copy</mat-icon>
            <ng-template #copy>
              <mat-icon color="primary" aria-label="Copied">done</mat-icon>
            </ng-template>
          </button>
          <pre [innerHtml]="json | prettyjson:2"></pre>
        </div>
      </div>
    </div>
  </div>
</div>
import {
  Component,
  OnInit
} from '@angular/core'
import { NotificationService } from '../../notification.service'
import { NotificationActions } from '../../notification-actions'

@Component({
  selector: 'notifications',
  templateUrl: 'notifications.component.html',
  styleUrls: ['notifications.component.scss']
})

export class NotificationsComponent implements OnInit {

  notifications: any[] = []

  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.subscribeEvents()
  }

  private subscribeEvents(): void {
    this.notificationService
      .actionStream$
      .subscribe(
        (entry: any) => {
          switch (entry.action) {
            case 'remove':
              this.remove(entry)
              break
            case 'add':
              this.add(entry)
              break
          }
        },
        (error: any) => this.handleError(error))
  }

  private remove(entry: NotificationActions): void {
    this.notifications.splice(entry.index, 1)
  }

  private add(entry: NotificationActions): void {
    this.notifications.push(entry.item)
  }

  private handleError(error: any): void {
    const err = {
      type: 'error',
      state: 'fromRight',
      progressBar: true,
      duration: 3000,
      title: 'Error!',
      content: `${error}`
    }
    this.notifications.push(err)
  }
}

import { Pipe, PipeTransform } from '@angular/core'
import { User } from '../../../share/models/user.model'

@Pipe({ name: 'userRole' })

export class UserRolePipe implements PipeTransform {
  transform(user: User): string {
    switch (true) {
      case user.role === 'admin':
        return 'God'
      case user.role === 'customer':
        return 'Customer'
      case user.employee.administrator && user.role === 'employee':
        return 'Administrator'
      case user.employee.artdirector && user.role === 'employee':
        return 'Artdirector'
      case user.employee.support && user.role === 'employee':
        return 'Support'
      case user.employee.marketing && user.role === 'employee':
        return 'Marketing'
      case user.employee.extmarketing && user.role === 'employee':
        return 'Extended marketing'
      case user.employee.stockmanager && user.role === 'employee':
        return 'Stockmanager'
      case user.employee.sales && user.role === 'employee':
        return 'Sales'
      default:
        return 'UNKNOWN ROLE!!'
    }
  }
}

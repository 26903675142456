import { Injectable } from '@angular/core'
import { SocketIoService } from './socket-io.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable()
export class PageService {

  constructor(private socket: SocketIoService) { }

  clone(params: {
    id: string,
    targetWebsite: string,
    pageId: string,
    __v: number,
  }, { slug }: { slug?: string }): Observable<any> {
    return this.socket.emit('offWebsitePage.clone', { ...params, data: { slug } })
      .pipe(map(({ data }: { data: any }) => data))
  }

  get(where: any): Observable<any> {
    return this.socket.emit('offWebsitePage.get', { query: { filter: where } })
      .pipe(map(({ data }: { data: any }) => data))
  }
}

import { map } from 'rxjs/operators'
import { SocketIoService } from '../../share/services/socket-io.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Organization } from '../interfaces/organization'


@Injectable()
export class OrganizationService {
  constructor(private socket: SocketIoService) { }

  list(data: any): Observable<Organization[]> {
    return this.socket.emit('offOrganization.list', data).pipe(map((res: any) => res.data))
  }

  get(_id: string): Observable<Organization> {
    return this.socket.emit('offOrganization.getOne', { _id })
      .pipe(map((res: any) => res.data))
  }

  getSubscription(data: { entityId: string }): Observable<any> {
    return this.socket.emit('offChargebee.getSubscription', data).pipe(map((response: any) => response.data))
  }

  chekPlanForChange(data: { id: string, plan: string }): Observable<any> {
    return this.socket.emit('offOrganization.chekPlanForChange', data).pipe(map((response: any) => response.data))
  }

  changePlan(data: { id: string, plan: string }): Observable<any> {
    return this.socket.emit('offOrganization.changePlan', data).pipe(map((response: any) => response.data))
  }

  setHtmlInjection(id: string, htmlInjection: any): Observable<any> {
    return this.socket.emit('offOrganization.setHtmlInjection', { id, data: { htmlInjection } })
      .pipe(map((response: any) => response.data))
  }

  updateClientPlan(data: any): Observable<any> {
    return this.socket.emit('offOrganization.updateClientPlan', data).pipe(map((response: any) => response.data))
  }

  setDomain(_id: string, data: { domain: string, owner: string }): Observable<any> {
    return this.socket.emit('offOrganization.setDomain', { _id, data })
      .pipe(map((res: any) => res.data))
  }

  update(id: string, data: {
    name?: string, resourceBucket?: string, webhooks?: { url: string }[] | [], 'secret.googleMapKey'?: string,
    'secret.google'?: any, 'secret.facebook'?: any
    page403?: string, page404?: string, page423?: string, page503?: string,
  }): Observable<any> {
    return this.socket.emit('offOrganization.update', { id, data })
      .pipe(map((res: any) => res.data))
  }

  removeDomain(_id: string, data: { owner: string }): Observable<any> {
    return this.socket.emit('offOrganization.setDomain', { _id, data: { ...data, domain: null } })
      .pipe(map((res: any) => res.data))
  }

  remove(_id: string): Observable<any> {
    return this.socket.emit('offOrganization.remove', { _id })
  }

  setGoogleSpreadsheetSubscription(_id: string, data: any): Observable<any> {
    return this.socket.emit('offOrganization.setGoogleSpreadsheetSubscription', { _id, data })
  }
}

import { Injectable } from '@angular/core'
import { Router, Routes } from '@angular/router'
import { get as _get } from 'lodash'
import { Account } from '../../../models/account.model'

@Injectable()
export class NavMenuService {

  protected _currentMenuItem = {}
  _currentUserRole: string

  constructor(private _router: Router) {
  }

  public convertRoutesToMenus(menuRoutes: Routes): any[] {
    const items = this._convertArrayToItems(menuRoutes)
    return this._skipEmpty(items)
  }

  public filterAccess(item: any) {
    const {serviceAccount = false} = Account.current.options || {}
    return (Account.current.canAccess(item.accessFor) && (Account.current.gsuite || serviceAccount)) || (item.accessFor.indexOf('any') > -1)
  }

  public getCurrentItem(): any {
    return this._currentMenuItem
  }

  public selectMenuItem(menuItems: any[], currentRoute?: any): any[] {
    const items: any[] = []
    menuItems.forEach((item: any) => {
      item.selected = _get(item, '_id') === (_get(currentRoute, 'snapshot.data._id') ||
        _get(currentRoute, 'snapshot.data.parent'))

      if (item.selected) {
        this._currentMenuItem = item
      }

      if (item.children && item.children.length > 0) {
        item.children = this.selectMenuItem(item.children, currentRoute)
        item.selected = item.children.some((child: any) => child.selected)
      }

      items.push(item)
    })
    return items
  }

  protected _skipEmpty(items: any[]): any[] {
    const menu: any[] = []
    items.forEach((item: any) => {
      let menuItem
      if (item.skip) {
        if (item.children && item.children.length > 0) {
          menuItem = item.children
        }
      } else {
        menuItem = item
      }

      if (menuItem) {
        menu.push(menuItem)
      }
    })

    return [].concat.apply([], menu)
  }

  protected _convertArrayToItems(menuRoutes: any[], parent?: any): any[] {
    const items: any[] = []
    menuRoutes.forEach((route: any) => {
      items.push(this._convertObjectToItem(route, parent))
    })
    return items
  }

  protected _convertObjectToItem(object: any, parent?: any): any {
    let item: any = {}
    if (object.data && object.data.menu) {
      // this is a menu object
      item = object.data.menu
      item.route = object
      delete item.route.data.menu
    } else {
      item.route = object
      item.skip = true
    }

    // we have to collect all paths to correctly build the url then
    item.route.paths = parent && parent.route && parent.route.paths
      ? parent.route.paths.slice(0)
      : []
    item.route.path !== '' && item.route.paths.push(item.route.path)

    if (object.children && object.children.length > 0) {
      const childrenHaveTitle = object.children.some((childItem: any) => {
        return childItem.data && childItem.data.menu && childItem.data.menu.title
      })

      if (childrenHaveTitle || item.skip) {
        item.children = this._convertArrayToItems(object.children, item)
      }
    }

    const prepared = this._prepareItem(item)

    // if current item is selected or expanded - then parent is expanded too
    if ((prepared.selected || prepared.expanded) && parent) {
      parent.expanded = true
    }

    return prepared
  }

  protected _prepareItem(object: any): any {
    if (!object.skip) {
      object.access = this.filterAccess(object)

      const itemUrl = this._router.serializeUrl(this._router.createUrlTree(object.route.paths))
      object.url = object.url ? object.url : '#' + itemUrl

      object.target = object.target || ''
      return this._selectItem(object)
    }

    return object
  }

  protected _selectItem(object: any): any {
    if (object.children) {
      object.selected = object.url === ('#' + this._router.url)
    } else {
      object.selected = ('#' + this._router.url).indexOf(object.url) === 0
    }
    return object
  }
}

const socketClient = require("socket.io-client")
const debug = require("debug")
const EventEmitter = require("events")
const {version} = require("./package.json")

const requestLogger = debug("weblium:api-client:request")
requestLogger.color = "darkorchid"
const successLogger = debug("weblium:api-client:success")
successLogger.color = "forestgreen"
const failureLogger = debug("weblium:api-client:failure")
failureLogger.color = "crimson"

function attach(_socket, attachOpts = {}) {
  // _socket = "https://api.weblium.io/"
  let socket
  let connectPromise
  let handleConnect = null
  let promises = {}
  let refreshToken = attachOpts.refreshToken
  let token = attachOpts.token
  let msgId = 0
  const throwErrorOnStatus = attachOpts.throwErrorOnStatus == null ? true : !!attachOpts.throwErrorOnStatus
  const emitter = new EventEmitter()
  const {origin} = new URL(_socket.replace(/^ws(s?):/, "http$1:"))

  async function _callApi(body, opts = {}, __socket) {
    const socket = __socket || await handleConnect()

    const TIMEOUT = opts.timeout || attachOpts.timeout || 5000
    body = body || {}
    body.action === "auth.logout" && (token = refreshToken = null)

    ;["socket.setToken", "auth.login"].includes(body.action) && (body.socketioVersion = version)
    const messageId = ++msgId
    socket.emit("api", Object.assign(body, {messageId}))
    requestLogger("%s %s %o", messageId, body.action, body, "")
    return new Promise((resolve, reject) => {
      const timerId = setTimeout(() => {
        if(promises[messageId]) {
          delete promises[messageId]
          const error = new Error("Timeout expired")
          error.code = "TimeoutExpired"
          error.message = "Timeout expired"
          error.stack = opts.callStack ? `${error}\n${opts.callStack}` : error.stack
          reject && reject(error)
          resolve = reject = null
        }
      }, TIMEOUT)
      promises[messageId] = Object.assign({}, {request: body}, opts, {
        resolve(...args) {
          delete promises[messageId]
          clearTimeout(timerId)
          successLogger("%s %s %o", messageId, body.action, {args}, "")
          resolve && resolve(...args)
          resolve = reject = null
          if(["socket.token"].includes(body.action)) {
            emitter.emit("authenticated")
          }
        },
        reject(...args) {
          delete promises[messageId]
          clearTimeout(timerId)
          failureLogger("%s %s %o", messageId, body.action, {args}, "")
          reject && reject(...args)
          resolve = reject = null
        }
      })
    })
  }

  function callApi(body, opts = {}, __socket) {
    const result = _callApi(body, opts, __socket)
    result.data = () => result.then(({data}) => data)
    result.assert = () => result.then(({error, data}) => {
      if(error) {
        const {message, ...err} = error
        error = Object.assign(new Error(message || err.code || ""), err)
        error.stack = opts.callStack ? `${error}\n${opts.callStack}` : error.stack
        throw error
      }
      return data
    })
    return result
  }

  function close() {
    if(socket) {
      const _soc = socket
      socket = connectPromise = null
      _soc.close()
      const _promises = promises
      promises = {}

      for(const i in _promises) {
        const promise = _promises[i]
        delete _promises[i]
        const error = {
          message: "Connection is closed",
          name: "ConnectionClosed",
          code: "ECONCLOSED",
          request: promise.request,
        }
        promise.resolve({error, status: 503})
      }
      emitter.emit("closeConnection", _soc)
    }
  }

  function apiProxy(path, hash = {}) {
    path = path || []
    const exclude = ["call", "apply", "inspect", "valueOf", "name", "then", "catch", "next"]
    function get(target, prop) {
      if(exclude.includes(prop)) {
        return target[prop]
      }
      if(typeof prop === "string") {
        if(!(prop in hash)) {
          if(!path.length && prop != "domain" && prop in emitter) {
            hash[prop] = (...args) => emitter[prop](...args)
          } else {
            const [sub, ...other] = prop.split(".");
            let proxy = hash[sub] = apiProxy([...path, sub], {})
            for(let i = 0; i < other.length; i++) proxy = proxy[other[i]]
            return proxy
          }
        }
        return hash[prop]
      }
    }

    return new Proxy(function caller(body, opts) {
      const error = new Error("")
      Error.captureStackTrace && Error.captureStackTrace(error, caller)
      const callStack = error.stack.replace(/^(.*)?\r?\n/m, "")
      return callApi(Object.assign({}, body, {action: path.join(".")}), {...opts, callStack})
    }, {
      get,
      set(target, prop, value) {
        hash[prop] = value
        return true
      },
      deleteProperty(target, prop) {
        delete hash[prop]
        return true
      }
    })
  }

  handleConnect = () => connectPromise = connectPromise || new Promise((resolve, reject) => {
    const socketOpts = Object.assign({transports: ["websocket", "polling"]}, attachOpts.socket)
    const sock = typeof _socket == "string" ? socketClient(_socket, socketOpts) : _socket

    sock.on("connect", async args => {
      if(refreshToken) {
        await callApi({action: "auth.login", query: {refreshToken}}, {}, sock).catch(error => {
          error.status == 401 && (refreshToken = token = null)
        })
        refreshToken && emitter.emit("login", {refreshToken})
      } else if(token) {
        await callApi({action: "socket.setToken", data: {token}}, {}, sock).catch(error => {
          error.status == 401 && (refreshToken = token = null)
        })
      }
      resolve && resolve(socket = sock)
      resolve = reject = null
      emitter.emit("connect", args)
    })
    sock.on("api", async data => {
      // console.log(">>", data, promises[data.messageId])
      try {
        if(data) {
          if(!data.messageId) {
            emitter.emit("notify", data)
          } else {
            if(!promises[data.messageId]) {
              /*eslint no-console: ["error", { allow: ["warn", "error"] }]*/
              console.warn("There is no handle for response ", data)
              return
            }
            // keepConnection()
            const promise = promises[data.messageId]
            try {
              promise.request.action === "auth.login" && data.data && (
                refreshToken = (await callApi({action: "socket.refreshToken"}, {}, sock)).data,
                  token = (await callApi({action: "socket.token"}, {}, sock)).data
              )
            } catch(error) {
              console.error("unknown error", error)
              return promise.reject(error)
            }
            delete promises[data.messageId]
            data.request = promise.request
            if(!["auth.login", "auth.logout"].includes(promise.request.action) && data.error && refreshToken && (
              data.status == 401 &&
              ["api.auth.unauthorized", "api.auth.token_expired", "api.auth.invalid_token"].includes(data.error.code)
            )) {
              // console.log(data.error)
              const t = refreshToken
              token = refreshToken = null
              await callApi({action: "auth.login", query: {refreshToken: t}}, {}, sock).catch(() => {})
              if(token && refreshToken) {
                emitter.emit("login", {token, refreshToken})
                emitter.emit("relogin", {token, refreshToken})
                return await callApi(promise.request, promise, sock).then(promise.resolve).catch(promise.reject)
              }
            }
            if(data.error && throwErrorOnStatus) {
              const {message, ...err} = data.error
              const error = Object.assign(new Error(message || err.code || ""), err)
              error.stack = promise.callStack ? `${error}\n${promise.callStack}` : error.stack
              error.data = err
              promise.reject(error)
            } else {
              promise.resolve(data)
            }
          }
        }
      } catch(error) {
        console.error(error, error.stack)
      }
    })

    sock.on("error", error => {
      emitter.emit("error", error)
      reject && reject(error)
      resolve = reject = null
    })

    sock.on("reconnect_failed", () => {
      reject && reject(new Error("socket-io reconnect failed"))
      resolve = reject = null
    })
    sock.on("disconnect", arg => {
      emitter.emit("disconnect", arg)
      socket === sock && close()
    })
    sock.on("close", arg => {
      emitter.emit("closeConnection", arg)
    })
  })

  return apiProxy(null, {
    close,
    get refreshToken() {return refreshToken},
    get token() {return token},
    get rawSocket() {return socket},
    get requests() {return Object.values(promises)},
    connect() {return handleConnect()}
  })
}

module.exports = attach

import { IUserJson } from '../interfaces/user'
import * as moment from 'moment'
import UserAddress from './address.model'

export class User {
  _id: string
  name?: string
  registrar?: string
  password?: string
  lastName?: string
  currency?: string
  status?: string
  address?: UserAddress
  phoneNumber?: string
  avatar?: any
  externalDomainLimit?: number
  serviced_domain_limit?: number
  openai_completions_count?: number
  websiteTrialAndFreeAndUnpaidLimit?: number
  language?: string
  createdAt: any
  updatedAt: any
  registeredAt: any
  customDomain: string
  structureCustomDomain: string
  subscription: any
  country?: string
  registeredFrom?: {
    country?: string
    lat: string
    lon: string
    ip: string
  }
  blocked?: {
    at?: string,
    by?: string
  }
  draftiumPro?: any
  employee: {
    administrator?: boolean
    artdirector?: boolean
    marketing?: boolean
    extmarketing?: boolean
    support?: boolean
    stockmanager?: boolean
    sales?: boolean
  }
  customer: any
  options: any
  unverified?: boolean
  usernameWas?: string
  facebook?: Object
  facebookDraftium?: Object
  google?: Object
  googleDraftium?: Object
  organization: any
  websitePlans: any
  labels?: string[]
  giftDomains: Array<String>

  static fromJSON(json: IUserJson): User {
    const user = Object.create(User.prototype)
    return Object.assign(user, json, {
      createdAt: moment(json.createdAt),
      updatedAt: moment(json.createdAt),
      registeredAt: moment(json.registeredAt),
    })
  }

  static reviver(key: string, value: any): any {
    return key === '' ? User.fromJSON(value) : value
  }

  constructor(public role: string, public username: string) {
  }

  toJSON(): IUserJson {
    return Object.assign({ username: this.username, role: this.role, _id: this._id }, this, {
      createdAt: this.createdAt.format('YYYY-MM-DD HH:mm:ss'),
      registeredAt: this.registeredAt.format('YYYY-MM-DD HH:mm:ss'),
      updatedAt: this.createdAt.format('YYYY-MM-DD HH:mm:ss')
    })
  }

  getEmployeeRole() {
    switch (true) {
      case this.employee.administrator:
        return 'administrator'
      case this.employee.artdirector:
        return 'artdirector'
      case this.employee.marketing:
        return 'marketing'
      case this.employee.stockmanager:
        return 'stockmanager'
      case this.employee.sales:
        return 'sales'
      default:
        return 'support'
    }
  }
}

import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  }
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    useHash: true, relativeLinkResolution: 'legacy', paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

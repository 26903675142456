import { NotificationService } from '../../../../../notification-service/notification.service'
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core'
import {
  isEmpty as _isEmpty,
  chunk as _chunk,
  reduce as _reduce
} from 'lodash'
import { GalleryService } from '../../../../gallery.service'
import { GalleryResource } from '../../../../share/gallery-resource'
import { SpinnerService } from '../../../../../../../share/modules/spinner/spinner.service'
import { Account } from './../../../../../../models/account.model'
import { ANIMATION } from '../../../../../../animations'
import { ConfirmDialog } from '../../../../../confirm-dialog/confirm'
import { ConfirmDialogComponent } from '../../../../../confirm-dialog/confirm-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { ErrorHandlerService } from '../../../../../../services'
import { IConfigNotification } from '../../../../../notification-service/share/i-config-notification'

const bottomSlide = ANIMATION.bottomSlide,
  rightSlide = ANIMATION.rightSlide

@Component({
  selector: 'gallery-images',
  templateUrl: 'gallery-images.component.html',
  styleUrls: ['gallery-images.component.scss'],
  animations: [bottomSlide, rightSlide]
})

export class GalleryImagesComponent implements OnInit, OnChanges {
  @Input() imgConfig: any
  @Output() animStatus = new EventEmitter()

  account: Account
  searchValue: string
  json: any
  images: GalleryResource[]
  chunkImages: GalleryResource[][]
  total: number
  skip: number


  constructor(
    private notify: NotificationService,
    private dialog: MatDialog,
    private handler: ErrorHandlerService,
    private spinner: SpinnerService,
    private galleryService: GalleryService) {
  }

  ngOnInit() {
    this.account = Account.current
  }

  ngOnChanges(change: SimpleChanges): void {
    (change as any).imgConfig.previousValue !== (change as any).imgConfig.currentValue && this.loadFiles()
  }

  get hasImages() {
    return !_isEmpty(this.images)
  }

  emitAnim(): void {
    this.animStatus.emit()
  }

  trackByFn(index: number): number {
    return index
  }

  searchFiles(rewrite = true): void {
    this.spinner.show()

    if (this.imgConfig.searchResource) {
      this.galleryService
        .searchResourceFiles(
          this.imgConfig.service.searchFilesAction,
          this.searchValue,
          this.skip,
          this.imgConfig.service.defaultLimit,
          this.imgConfig.service.owner
        )
        .subscribe(
          (response: any) => this.filesLoaded(response, rewrite),
          error => (this.images = null, this.chunkImages = null, this.handleError(error)))
    } else {
      this.galleryService
        .searchFiles(
          this.imgConfig.service.searchFilesAction,
          this.searchValue,
          this.skip,
          this.imgConfig.service.defaultLimit
        )
        .subscribe(
          (response: any) => this.filesLoaded(response, rewrite),
          error => (this.images = null, this.chunkImages = null, this.handleError(error)))
    }
  }

  chunkImgs(): void {
    this.chunkImages = _chunk(this.images, 4)
  }

  loadMore(): void {
    this.skip = +this.skip + 40
    this.searchValue ? this.searchFiles(false) : this.loadFiles(false)
  }

  remove(id: string): void {
    const data: ConfirmDialog = {
      title: 'Are you sure delete this image ?',
      text: 'Once you delete image, there is no going back. Please be certain.',
      confirmBtnTxt: 'Delete',
      confirmBtnColor: 'warn',
      ...this.account.canAccess('admin') ? {
        force: {
          value: false,
          msg: 'Please be careful! Resource will be completely removed from bucket and all websites!'
        }
      } : {}
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: 'auto',
      width: '400px',
      data
    })
    dialogRef.afterClosed().subscribe((res: boolean) =>
      res && this.deleteResource(id, data.force ? data.force.value : false))
  }

  show(id: string): void {
    this.galleryService.resourceShowSource.next(id)
  }

  deleteAll(): void {
    const data: ConfirmDialog = {
      title: 'Are you sure delete images ?',
      text: "Once you delete images, there's no going back. Please be certain.",
      confirmBtnTxt: 'Delete',
      confirmBtnColor: 'warn'
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      height: 'auto',
      width: '400px',
      data
    })
    dialogRef.afterClosed().subscribe((res: boolean) => res && this.deleteAllImages())
  }

  private loadFiles(rewrite = true): void {
    this.spinner.show()
    this.galleryService
      .getFiles(this.imgConfig.service.getFilesAction,
        this.skip,
        this.imgConfig.service.defaultLimit,
        this.imgConfig.service.owner || undefined
      )
      .subscribe(
        (response: any) => this.filesLoaded(response, rewrite),
        error => (this.images = null, this.chunkImages = null, this.handleError(error)))
  }

  private handleError(err: any): void {
    this.spinner.hide()
    this.handler.notifyError(err)
  }

  private filesLoaded(response: { data: GalleryResource[]; total: number; skip: number }, rewrite: boolean): void {
    this.spinner.hide()
    this.json = response
    if (rewrite) {
      this.images = response.data.map(it => (it.name == it.name || "", it))
    } else {
      for (const image of response.data) {
        image.name = image.name || ""
        this.images.push(image)
      }
    }
    this.chunkImgs()

    this.skip = response.skip
    this.total = response.total
    this.emitAnim()
  }

  private deleteResource(id: string, force = false): void {
    this.galleryService.deleteFile(this.imgConfig.service.deleteFileAction, id, {force})
      .subscribe((res: any) => !res.error
        ? this.resourceDeleted(res.data)
        : this.handleError(res.error.message || res.error.code))
  }

  private resourceDeleted(response: any): void {
    response.n && (this.searchValue ? this.searchFiles() : this.loadFiles())
  }

  private deleteAllImages(): void {
    _reduce(this.images, (promise: any, item: any) => promise
      .then(() => new Promise((resolve: any, reject: any) =>
        this.galleryService.deleteFile(this.imgConfig.service.deleteFileAction, item._id)
          .subscribe((res: any) => !res.error
            ? resolve()
            : reject(res.error.message || res.error.code))
      )),
      Promise.resolve([])).then(() =>
      (this.notify.add(<IConfigNotification>{
        title: 'Success',
        type: 'success',
        content: 'Images deleted'
      }), this.searchValue ? this.searchFiles() : this.loadFiles()))
  }
}

import { Injectable } from '@angular/core'
import { BaThemeConfigProvider } from '../../../theme'

@Injectable()
export class MapChartThemeService {

  constructor(private _baConfig: BaThemeConfigProvider) {
  }

  getTheme() {
    const layoutColors = this._baConfig.get().colors

    return {
      themeName: 'blur',
      AmChart: {
        color: layoutColors.defaultText,
        backgroundColor: '#FFFFFF'
      },

      AmCoordinateChart: {
        colors: [
          layoutColors.primary,
          layoutColors.danger,
          layoutColors.warning,
          layoutColors.success,
          layoutColors.info,
          layoutColors.primaryDark,
          layoutColors.warningLight,
          layoutColors.successDark,
          layoutColors.successLight,
          layoutColors.primaryLight,
          layoutColors.warningDark]
      },
      AmStockChart: {
        colors: [
          layoutColors.primary,
          layoutColors.danger,
          layoutColors.warning,
          layoutColors.success,
          layoutColors.info,
          layoutColors.primaryDark,
          layoutColors.warningLight,
          layoutColors.successDark,
          layoutColors.successLight,
          layoutColors.primaryLight,
          layoutColors.warningDark]
      },

      AmSlicedChart: {
        colors: [
          layoutColors.primary,
          layoutColors.danger,
          layoutColors.warning,
          layoutColors.success,
          layoutColors.info,
          layoutColors.primaryDark,
          layoutColors.warningLight,
          layoutColors.successDark,
          layoutColors.successLight,
          layoutColors.primaryLight,
          layoutColors.warningDark],
        labelTickColor: '#FFFFFF',
        labelTickAlpha: 0.3
      },

      AmRectangularChart: {
        zoomOutButtonColor: '#FFFFFF',
        zoomOutButtonRollOverAlpha: 0.15,
        zoomOutButtonImage: 'lens.png'
      },

      AxisBase: {
        axisColor: '#FFFFFF',
        axisAlpha: 0.3,
        gridAlpha: 0.1,
        gridColor: '#FFFFFF'
      },

      ChartScrollbar: {
        backgroundColor: '#FFFFFF',
        backgroundAlpha: 0.12,
        graphFillAlpha: 0.5,
        graphLineAlpha: 0,
        selectedBackgroundColor: '#FFFFFF',
        selectedBackgroundAlpha: 0.4,
        gridAlpha: 0.15
      },

      ChartCursor: {
        cursorColor: layoutColors.primary,
        color: '#FFFFFF',
        cursorAlpha: 0.5
      },

      AmLegend: {
        color: '#FFFFFF'
      },

      AmGraph: {
        lineAlpha: 0.9
      },
      GaugeArrow: {
        color: '#FFFFFF',
        alpha: 0.8,
        nailAlpha: 0,
        innerRadius: '40%',
        nailRadius: 15,
        startWidth: 15,
        borderAlpha: 0.8,
        nailBorderAlpha: 0
      },

      GaugeAxis: {
        tickColor: '#FFFFFF',
        tickAlpha: 1,
        tickLength: 15,
        minorTickLength: 8,
        axisThickness: 3,
        axisColor: '#FFFFFF',
        axisAlpha: 1,
        bandAlpha: 0.8
      },

      TrendLine: {
        lineColor: layoutColors.danger,
        lineAlpha: 0.8
      },

      // ammap
      AreasSettings: {
        alpha: 0.8,
        color: layoutColors.info,
        colorSolid: layoutColors.primaryDark,
        unlistedAreasAlpha: 0.4,
        unlistedAreasColor: '#FFFFFF',
        outlineColor: '#FFFFFF',
        outlineAlpha: 0.5,
        outlineThickness: 0.5,
        rollOverColor: layoutColors.primary,
        rollOverOutlineColor: '#FFFFFF',
        selectedOutlineColor: '#FFFFFF',
        selectedColor: '#f15135',
        unlistedAreasOutlineColor: '#FFFFFF',
        unlistedAreasOutlineAlpha: 0.5
      },

      LinesSettings: {
        color: '#FFFFFF',
        alpha: 0.8
      },

      ImagesSettings: {
        alpha: 0.8,
        labelColor: '#FFFFFF',
        color: '#FFFFFF',
        labelRollOverColor: layoutColors.primaryDark
      },

      ZoomControl: {
        buttonFillAlpha: 0.8,
        buttonIconColor: 'rgba(255,255,255,.7)',
        buttonRollOverColor: 'rgba(0,0,0,0.54)',
        buttonFillColor: 'rgba(0,0,0,0.3)',
        buttonBorderColor: 'rgba(0,0,0,0.54)',
        buttonBorderAlpha: 0,
        buttonCornerRadius: 0,
        gridColor: 'transparent',
        gridBackgroundColor: 'transparent',
        buttonIconAlpha: 1,
        gridAlpha: 1,
        buttonSize: 26
      },

      SmallMap: {
        mapColor: '#000000',
        rectangleColor: layoutColors.danger,
        backgroundColor: '#FFFFFF',
        backgroundAlpha: 0.7,
        borderThickness: 1,
        borderAlpha: 0.8
      },

      // the defaults below are set using CSS syntax, you can use any existing css property
      // if you don't use Stock chart, you can delete lines below
      PeriodSelector: {
        color: '#FFFFFF'
      },

      PeriodButton: {
        color: '#FFFFFF',
        background: 'transparent',
        opacity: 0.7,
        border: '1px solid rgba(0, 0, 0, .3)',
        MozBorderRadius: '5px',
        borderRadius: '5px',
        margin: '1px',
        outline: 'none',
        boxSizing: 'border-box'
      },

      PeriodButtonSelected: {
        color: '#FFFFFF',
        backgroundColor: '#b9cdf5',
        border: '1px solid rgba(0, 0, 0, .3)',
        MozBorderRadius: '5px',
        borderRadius: '5px',
        margin: '1px',
        outline: 'none',
        opacity: 1,
        boxSizing: 'border-box'
      },

      PeriodInputField: {
        color: '#FFFFFF',
        background: 'transparent',
        border: '1px solid rgba(0, 0, 0, .3)',
        outline: 'none'
      },

      DataSetSelector: {
        color: '#FFFFFF',
        selectedBackgroundColor: '#b9cdf5',
        rollOverBackgroundColor: '#a8b0e4'
      },

      DataSetCompareList: {
        color: '#FFFFFF',
        lineHeight: '100%',
        boxSizing: 'initial',
        webkitBoxSizing: 'initial',
        border: '1px solid rgba(0, 0, 0, .3)'
      },

      DataSetSelect: {
        border: '1px solid rgba(0, 0, 0, .3)',
        outline: 'none'
      }
    }
  }

}

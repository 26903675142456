import { NotificationService } from './../../../../../notification-service/notification.service'
import { Component, Input, AfterContentInit } from '@angular/core'
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms'
import { GalleryResource } from '../../../../share/gallery-resource'
import { GalleryService } from '../../../../gallery.service'
import { isEmpty as _isEmpty } from 'lodash'
import { IConfigNotification } from '../../../../../notification-service/share/i-config-notification'

const openai_color_roles = [
  "openai_color_black_and_white", "openai_color_black", "openai_color_white", "openai_color_yellow",
  "openai_color_orange", "openai_color_red", "openai_color_purple", "openai_color_magenta", "openai_color_green",
  "openai_color_teal", "openai_color_blue"
]

@Component({
  selector: 'gallery-resource-edit',
  templateUrl: 'gallery-resource-edit.component.html',
  styleUrls: ['gallery-resource-edit.component.scss']
})

export class GalleryResourceEditComponent implements AfterContentInit {
  @Input() resource: GalleryResource
  @Input() moduleConfig: any

  form: FormGroup
  tagsControls: FormControl[] = []
  description: string
  roleOpenai: boolean
  openai_color_black_and_white: boolean
  openai_color_black: boolean
  openai_color_white: boolean
  openai_color_yellow: boolean
  openai_color_orange: boolean
  openai_color_red: boolean
  openai_color_purple: boolean
  openai_color_magenta: boolean
  openai_color_green: boolean
  openai_color_teal: boolean
  openai_color_blue: boolean
  private name: string


  constructor(
    private notify: NotificationService,
    private galleryService: GalleryService,
    private fb: FormBuilder) {
  }

  ngAfterContentInit(): void {
    this.initComponent()
  }

  onSubmit(data: any): void {
    data.tags && data.tags.join(' ')
    data.roles = data.roles || []
    this.roleOpenai && !data.roles.includes("openai") && data.roles.push("openai")
    !this.roleOpenai && (data.roles = data.roles.filter(it => it != "openai"))
    for(var role of openai_color_roles) {
      this[role] && !data.roles.includes(role) && data.roles.push(role)
      !this[role] && (data.roles = data.roles.filter(it => it != role))
    }

    this.galleryService
      .updateResource(
        this.moduleConfig.service.updateResourceAction,
        this.resource._id,
        data).subscribe((res: any) => !res.error
          ? this.initModelFields(res.data as GalleryResource)
          : this.notify.add(<IConfigNotification>{
            type: 'error',
            title: 'Error !',
            content: res.error.message || res.error.code || res.error
          }))
  }

  get hasTags() {
    return !_isEmpty(this.tagsControls)
  }


  private initForm(): void {
    this.form = this.fb.group({ name: [this.name, Validators.compose([Validators.required])] })
  }

  private initComponent() {
    this.initModelFields()
    this.initForm()
  }

  private initModelFields(resource = null) {
    if(resource) {
      Object.keys(this.resource).forEach(key => delete this.resource[key])
      Object.assign(this.resource, resource)
    }
    this.roleOpenai = (this.resource.roles || []).includes("openai")
    for(const role of openai_color_roles) [
      this[role] = (this.resource.roles || []).includes(role)
    ]
    this.name = this.resource.name
    this.description = this.resource.description
  }
}

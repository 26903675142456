import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgxUploaderModule } from 'ngx-uploader'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { PictureUploaderComponent } from './picture-uploader.component'

const IMPORTS = [
  CommonModule,
  NgxUploaderModule,
  MatIconModule,
  MatTooltipModule
]

@NgModule({
  imports: [...IMPORTS],
  exports: [PictureUploaderComponent],
  declarations: [PictureUploaderComponent]
})

export class PictureUploaderModule { }

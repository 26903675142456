import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ConfirmComponent } from './confirm.component'
import { MatButtonModule } from '@angular/material/button'

@NgModule({
  imports: [MatButtonModule, CommonModule],
  exports: [ConfirmComponent],
  declarations: [ConfirmComponent]
})
export class ConfirmModule { }

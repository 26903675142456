<div *ngIf="chunkImages">
  <div class="container" *ngIf="chunkImages" [@rightSlide]>
    <div class="row">
      <div class="col-sm-12 search-form gallery-search">
        <mat-form-field appearance="outline">
          <mat-label>Search</mat-label>
          <input matInput [(ngModel)]="searchValue" #search placeholder="Search" (keyup.enter)="searchValue && searchFiles()">
        </mat-form-field>
        <button mat-fab [disabled]="!searchValue" class="search-btn" color="primary" type="button" (click)="searchValue && searchFiles()">
          <mat-icon aria-label="search">search</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="container m-b" *ngIf="chunkImages" [@bottomSlide]>
    <div class="row">
      <ng-template ngFor let-imgs [ngForOf]="chunkImages" [ngForTrackBy]="trackByFn" class="col-sm-12">
        <ng-template ngFor let-img [ngForOf]="imgs" [ngForTrackBy]="trackByFn">
          <div class="col-md-3 chunk">
            <mat-card>
              <img mat-card-image (click)="show(img._id)" [src]="img?.alias?.thumbnail?.url | protocolChecker"
                alt="...">
              <mat-card-content>
                <p class="img-name">
                  <strong>{{ img.name }}</strong>
                </p>
                <p>
                  <span>Dimension: </span>
                  <strong>{{ img?.props?.size?.width }} x {{ img?.props?.size?.height }}</strong>
                </p>
                <p>
                  <span>Size: </span>
                  <strong>{{ img?.props?.bytes/1024/1024 | number:'.2' }} MB</strong>
                </p>
                <a [href]="img?.url" target="_blank">Preview</a>
              </mat-card-content>
              <mat-card-actions *ngIf="account.canAccess(imgConfig.deleteBtnRoles)">
                <button mat-button color="primary" (click)="show(img._id)">Show details</button>
                <button mat-button color="warn" (click)="remove(img._id)">Delete</button>
              </mat-card-actions>
            </mat-card>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>

  <div class="container text-center" *ngIf="!hasImages">
    <div class="row not-found">
      <h3>
        Oops... Data not found.
      </h3>
    </div>
  </div>

  <div class="container m-b text-center">
    <div class="row">
      <div class="col-sm-12">
        <p class="total pull-left" *ngIf="total">Total: <strong>{{ total }}</strong>
        </p>
        <button mat-button class="more-btn" *ngIf="(skip + 40) <= total" color="primary" (click)="loadMore()">Load
          more...
        </button>
        <button mat-raised-button class="pull-right" *ngIf="images && images?.length > 1 && account.canAccess(imgConfig.deleteBtnRoles)"
          color="warn" (click)="deleteAll()">Delete all
        </button>
      </div>
    </div>
  </div>
</div>
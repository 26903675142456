import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ResolverComponent } from './resolver.component'

const IMPORTS = [
  CommonModule,
  MatButtonModule,
  MatMenuModule,
  MatIconModule
]

@NgModule({
  imports: [...IMPORTS],
  exports: [ResolverComponent],
  declarations: [ResolverComponent]
})
export class ResolverModule { }

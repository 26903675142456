<span *ngIf="!basicTableCell.linkBtn && basicTableCell && ((value | async) || (value | async) == 0)"
  [ngStyle]="{'color' : getColor()}" [ngClass]="{'with-subtext': subtext}">
  {{ value | async }}
  <small *ngIf="subtext">{{subtext}}</small>
</span>

<a href="#" (click)="linkBtn($event)"
  *ngIf="basicTableCell.linkBtn && basicTableCell && ((value | async) || (value | async) == 0)">
  {{ value | async }}
</a>

<span *ngIf="basicTableCell && !(value | async) && (value | async) !== 0" class="not-set">Not set</span>

<button *ngIf="basicTableCell.link && !basicTableCell.hideMenu && (value | async)" mat-icon-button
  class="basicTableCell-link" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
  <mat-icon>
    arrow_drop_down
  </mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button *ngIf="basicTableCellEntry.role !== 'admin'" mat-menu-item (click)="linkNavigate()">
    <mat-icon>launch</mat-icon>
    <span>Open new tab {{basicTableCell?.menuItem}}</span>
  </button>
  <button mat-menu-item ngxClipboard [cbContent]="value | async" (cbOnSuccess)="copyNotify()">
    <mat-icon>content_copy</mat-icon>
    <span>Copy text</span>
  </button>
</mat-menu>
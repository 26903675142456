import { Account } from './share/models/account.model'
import { UserStorageService } from './share/services/user-storage.service'
import { SpinnerService } from './share/modules/spinner/spinner.service'
import { AppStorage } from './share/services/app-storage.service'
import { ThemeService, SocketIoService } from './share/services'
import { Component, ElementRef, Renderer2 } from '@angular/core'
import { OverlayContainer } from '@angular/cdk/overlay'
import { get as _get } from 'lodash'

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  theme: string
  connectingMsg: string
  connected = false
  CONNECT_TIMEOUT = 5000
  RECONNECT_TIMEOUT = 2000
  RECONN_RETRIES = 0
  MAX_RECONN_RETRIES = 3
  _id: string

  constructor(
    private storage: AppStorage,
    private themeService: ThemeService,
    private socket: SocketIoService,
    private _overlayContainer: OverlayContainer,
    private _element: ElementRef,
    private _renderer: Renderer2,
    private spinner: SpinnerService,
    private userStorageService: UserStorageService
  ) {
    this._id = _get(Account, 'current._id')
    this.spinner.show()
    this.handleConnect()
    this.initTheme()
  }

  handleConnect(): void {
    const timeoutConnect = (): any => {
      return setTimeout(() => {
        this.socket.api.connect()
        setTimeout(() => this.connected = false, 0)
      }, this.CONNECT_TIMEOUT)
    }

    const reconnectInterval = (): any => {
      return setInterval(() => {
        this.socket.api.connect()
        this.RECONN_RETRIES++
        if (this.RECONN_RETRIES >= this.MAX_RECONN_RETRIES) {
          setTimeout(() => this.connected = false, 0)
        }
      }, this.CONNECT_TIMEOUT)
    }

    let connTimeout = timeoutConnect()
    let reconnInterval: any
    this.socket.api.connect()
    this.socket.api.on('connect', () => {
      this.spinner.hide()
      setTimeout(() => this.connected = true, 0)
      clearTimeout(connTimeout)
      reconnInterval && (clearInterval(reconnInterval), this.RECONN_RETRIES = 0)
    })
    this.socket.api.on('closeConnection', () => {
      this.socket.api.connect()
      reconnInterval = reconnectInterval()
    })
    this.socket.api.on('close', () => {
      connTimeout = timeoutConnect()
    })
  }

  toggleTheme(theme: string, _id: string): void {
    this.userStorageService.update({ key: 'setting', owner: this._id || _id, value: { theme } })
      .subscribe(() => {
        this._renderer.removeClass(this._element.nativeElement, this.theme)
        this._overlayContainer.getContainerElement().classList.remove(this.theme)
        this.theme = theme
        this.storage.set('settings', { theme })
        this._renderer.addClass(this._element.nativeElement, this.theme)
        this._overlayContainer.getContainerElement().classList.add(this.theme)
      })
  }

  private initTheme(): void {
    const settings = this.storage.get('settings')
    this.theme = settings && settings.theme || 'default'
    this._renderer.addClass(this._element.nativeElement, this.theme)
    this._overlayContainer.getContainerElement().classList.add(this.theme)
    this.themeService.themeStream$.subscribe(({ theme, _id }: { theme: string, _id: string }) =>
      this.toggleTheme(theme, _id))
  }
}

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  Output,
  Input,
  EventEmitter
} from '@angular/core'
import * as moment from 'moment'
import flatpickr from 'flatpickr'

@Component({
  selector: 'resolver',
  templateUrl: 'resolver.component.html',
  styleUrls: ['resolver.component.scss']
})

export class ResolverComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('calendar', { static: false }) calendar: ElementRef
  @Output() onClose = new EventEmitter<any>()
  @Input() disabledStatus: boolean
  calendarPicker: any

  constructor() { }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.calendar &&
      (this.calendarPicker = flatpickr(this.calendar.nativeElement,
        { disableMobile: true, minDate: 'today', enableTime: true, time_24hr: true }))
  }

  ngOnDestroy(): void {
    this.calendarPicker && this.calendarPicker.destroy()
  }

  checkCalendar(date: any): void {
    date && this.onClose.emit(date)
  }

  setDate(data: any[]): void {
    const date = moment().add(...data).format('YYYY-MM-DD HH:mm')
    this.onClose.emit(date)
  }

}

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EditDialogComponent } from './edit-dialog.component'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { NgaModule } from '../../../theme/nga.module'
import { ReactiveFormsModule } from '@angular/forms'

const IMPORTS = [
  CommonModule,
  MatFormFieldModule,
  MatDialogModule,
  MatButtonModule,
  MatInputModule,
  ReactiveFormsModule,
  NgaModule
]

@NgModule({
    imports: [...IMPORTS],
    declarations: [EditDialogComponent],
    exports: [EditDialogComponent]
})
export class EditDialogModule { }

import { Subscriber, Observable } from 'rxjs'
import { AfterContentInit, Component, Input } from '@angular/core'
import { get as _get } from 'lodash'
import { MatSnackBar } from '@angular/material/snack-bar'
import { COLORS } from '../../../../../../color-palette'

@Component({
  selector: '[basicTableCell]',
  templateUrl: 'basic-table-cell.component.html',
  styleUrls: ['basic-table-cell.component.scss'],
  providers: [MatSnackBar]
})

export class BasicTableCellComponent implements AfterContentInit {
  @Input() basicTableCell: any
  @Input() basicTableCellEntry: any
  @Input() status: boolean

  value: Observable<any>
  subtext: any

  constructor(private snackBar: MatSnackBar) { }

  ngAfterContentInit() {
    this.value = new Observable<any>((observer: Subscriber<any>) => this.initValue(observer))
  }

  getColor(): string {
    return (this.basicTableCell.status && COLORS[this.basicTableCell.status(this.basicTableCellEntry)]) || {}
  }

  linkNavigate(): void {
    this.basicTableCell.link && this.basicTableCell.link(this.basicTableCellEntry)
  }

  linkBtn(e: any): void {
    e.stopPropagation()
    e.preventDefault()
    this.basicTableCell.linkBtn.next(this.basicTableCellEntry)
  }

  copyNotify(): void {
    this.snackBar.open('Text copied', 'Hide', { duration: 2000 })
  }

  private initValue(observer: Subscriber<any>): void {
    this.basicTableCell.subtext && (this.subtext = this.basicTableCell.subtext(this.basicTableCellEntry))
    if (this.basicTableCell.template && typeof this.basicTableCell['template'] === 'function') {
      this.basicTableCell.template({
        observer,
        value: _get(this.basicTableCellEntry, this.basicTableCell.key),
        entry: this.basicTableCellEntry
      })
    } else {
      observer.next(_get(this.basicTableCellEntry, this.basicTableCell.key))
    }
  }
}

import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class SpinnerService {

  actionStream = new Subject<any>()
  actionStream$ = this.actionStream.asObservable()
  counter = 0

  constructor() {
  }

  show() {
    this.counter++
    this.actionStream.next(true)
  }

  hide() {
    this.counter && this.counter--
    !this.counter && this.actionStream.next(false)
  }

}

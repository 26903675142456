<div class="pull-right btn-container">
  <button mat-icon-button
          #prevBtn
          (click)="prev()"
          [disabled]="skip === 0"
          color="primary">
    <mat-icon>navigate_before</mat-icon>
  </button>
  <button mat-icon-button
          #nextBtn
          (click)="next()"
          [disabled]="!showNext" 
          color="primary">
    <mat-icon>navigate_next</mat-icon>
  </button>
</div>

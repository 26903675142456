import { PingCategory } from '../models/domain.model'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'pingMessage'
})
export class PingMessagePipe implements PipeTransform {

  transform(category: string): string {
    switch (category) {
      case PingCategory.Ok:
        return 'All good'
      case PingCategory.InvalidIp:
        return 'Invalid IP'
      case PingCategory.HtmlContent:
        return 'Html content error'
      case PingCategory.WebsiteNotFound:
        return 'Website not found'
      case PingCategory.SubdomainRedirect:
        return 'Invalid subdomain redirect'
      case PingCategory.RedirectToOther:
        return 'Subdomain redirects to other website'
      case PingCategory.SubdomainGet:
        return 'Can not load from subdomain'
      case PingCategory.DomainGet:
        return 'Can not load website from domain'
      case PingCategory.HasNoRender:
        return 'Website has no render'
      case PingCategory.Exception:
        return 'Exception error'
      case PingCategory.BucketGet:
        return 'Can not load from bucket'
      case PingCategory.WrongNS:
        return 'Wrong ns records'
      case PingCategory.Expired:
        return 'Website expired'
      case PingCategory.Suspended:
        return 'Website suspended'
      case PingCategory.Maintenance:
        return 'Website maintenance'
      default:
        return 'Unknown error'
    }
  }

}

import { trigger, style, transition, animate, state } from '@angular/animations'

export const ANIMATION = {
  emergence: trigger('emergence', [
    transition(
      ':enter', [
        style({ transform: 'scale(0)' }),
        animate('0.375s cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'scale(1)' }))
      ]
    )
  ]),
  leftSlide: trigger('leftSlide', [
    transition(
      ':enter', [
        style({ transform: 'translateX(-200%)' }),
        animate('0.375s cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'translateX(0)' }))
      ]
    ),
    transition(
      ':leave', [
        style({ transform: 'translateX(0)' }),
        animate('0.295s cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'translateX(-200%)' }))
      ]
    )
  ]),
  rightSlide: trigger('rightSlide', [
    transition(
      ':enter', [
        style({ transform: 'translateX(200%)' }),
        animate('0.375s cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'translateX(0)' }))
      ]
    ),
    transition(
      ':leave', [
        style({ position: 'absolute', transform: 'translateX(0)' }),
        animate('0.295s cubic-bezier(0.4, 0.0, 0.2, 1)', style({ position: 'absolute', transform: 'translateX(200%)' }))
      ]
    )
  ]),
  bottomSlide: trigger('bottomSlide', [
    transition(
      ':enter', [
        style({ transform: 'translateY(200%)' }),
        animate('0.375s cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'translateY(0)' }))
      ]
    ),
    transition(
      ':leave', [
        style({ transform: 'translateY(0)' }),
        animate('0.295s cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'translateY(200%)' }))
      ]
    )
  ]),
  rightSlideState: trigger('rightSlideState', [
    state('show', style({ transform: 'translateX(0)', position: 'relative' })),
    transition('* => show',
      [style({ transform: 'translateX(200%)', position: 'relative' }),
      animate('0.375s cubic-bezier(0.4, 0.0, 0.2, 1)')]
    )
  ]),
  leftSlideState: trigger('leftSlideState', [
    state('show', style({ transform: 'translateX(0)', position: 'relative' })),
    transition('* => show',
      [style({ transform: 'translateX(-200%)', position: 'relative' }),
      animate('0.375s cubic-bezier(0.4, 0.0, 0.2, 1)')]
    )
  ]),
  leftSlideNoneTransform: trigger('leftSlideNoneTransform', [
    state('show', style({ position: 'relative', left: '0%' })),
    transition('* => show',
      [style({ position: 'relative', left: '-100%' }),
      animate('0.375s cubic-bezier(0.4, 0.0, 0.2, 1)')]
    )
  ])
}

import { Pipe, PipeTransform } from '@angular/core'
import { User } from '../../share/models/user.model'

@Pipe({
  name: 'userRoleLable'
})
export class UserRoleLablePipe implements PipeTransform {
  transform(user: User): string {
    switch (true) {
      case user.role === 'admin':
        return 'primary'
      case user.role === 'customer':
        return 'info'
      case user.employee.administrator && user.role === 'employee':
        return 'primary'
      case user.employee.artdirector && user.role === 'employee':
        return 'warning'
      case user.employee.support && user.role === 'employee':
        return 'warning'
      case user.employee.marketing && user.role === 'employee':
        return 'success'
      case user.employee.stockmanager && user.role === 'employee':
        return 'danger'
      case user.employee.sales && user.role === 'employee':
        return 'success'
      default:
        return 'danger'
    }
  }
}

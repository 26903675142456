import { Injectable } from '@angular/core'
import { SocketIoService } from './socket-io.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable()
export class UserStorageService {

  constructor(private socket: SocketIoService) { }

  get({ key, owner, general }: { key: string, owner?: string, general?: boolean }): Observable<any> {
    return this.socket.emit('offUserStorage.get', { key, owner, ...general ? { data: { general } } : {} })
      .pipe(map(({ data }: { data: any }) => data))
  }

  getAll(owner?: string): Observable<any> {
    return this.socket.emit('offUserStorage.getAll', { owner })
      .pipe(map(({ data }: { data: any }) => data))
  }

  update(data: { key: string, owner?: string, value: any, general?: boolean }): Observable<any> {
    return this.socket.emit('offUserStorage.update', { data })
      .pipe(map(({ data }: { data: any }) => data))
  }

  setTableFilters(data: { filters: string, key: string }): Observable<any> {
    return this.socket.emit('offUserStorage.setTableFilters', { data }).pipe(map((res: any) => res.data))
  }

  getTableSettings(key: string): Observable<any> {
    return this.socket.emit('offUserStorage.getTableSetting', { key }).pipe(map((res: any) => res.data))
  }

  addTableFilter(data: { filter: { alias: any, filter: string }, key: string, general: boolean }): Observable<any> {
    return this.socket.emit('offUserStorage.addTableFilter', { data }).pipe(map((res: any) => res.data))
  }

  removeTableFilter(data: { _id: string, key: string, general: boolean }): Observable<any> {
    return this.socket.emit('offUserStorage.removeTableFilter', { data }).pipe(map((res: any) => res.data))
  }

  renameTableFilter(data: { alias: string, _id: string, key: string, general: boolean }): Observable<any> {
    return this.socket.emit('offUserStorage.renameTableFilter', { data }).pipe(map((res: any) => res.data))
  }
}

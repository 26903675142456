<h2 mat-dialog-title>{{data.title}}</h2>
<hr>
<mat-dialog-content>
  <p>{{data.text}}</p>
  <div *ngIf="data?.force">
    <mat-slide-toggle color="primary" [(ngModel)]="data.force.value">Force</mat-slide-toggle>
    <p *ngIf="data.force.value && data.force?.msg" class="top-buffer danger">
      {{data.force.msg}}
    </p>
  </div>
</mat-dialog-content>
<hr>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close [color]="data.cancelBtnColor || ''">
    {{data.cancelBtnTxt || 'Cancel'}}
  </button>
  <button mat-raised-button [mat-dialog-close]="true" [color]="data.confirmBtnColor ||  'primary'">
    {{data.confirmBtnTxt || 'Confirm'}}
  </button>
</mat-dialog-actions>

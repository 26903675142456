<div class="basic-table-template">
  <div class="row">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-12 head-btns">
              <!-- for upload and download website -->
              <a #csvFileLink [ngStyle]="{display: 'none'}"></a>
              <button *ngIf="tableData.csv" class="pull-right ml" (click)="downloadCsv()" mat-raised-button
                color="success">
                Download CSV
              </button>
              <button *ngIf="tableData.checkboxCol && tableData.deleteAll && access()" [disabled]="!selectList.length"
                class="pull-right ml" (click)="deleteAll()" mat-raised-button color="warn">
                Delete selected
              </button>

              <button *ngIf="tableData.checkboxCol && tableData?.markWeblium?.mark && access()"
                [disabled]="!selectList.length" class="pull-right ml" (click)="markWeblium(true)" mat-stroked-button
                color="primary">
                Mark weblium users
              </button>

              <button *ngIf="tableData.checkboxCol && tableData.markWeblium?.unmark && access()"
                [disabled]="!selectList.length" class="pull-right ml" (click)="markWeblium(false)" mat-stroked-button
                color="primary">
                Unmark weblium users
              </button>

              <button *ngIf="tableData.checkboxCol && tableData.unresolver && access()" [disabled]="!selectList.length"
                class="pull-right ml" (click)="unresolver()" mat-stroked-button color="primary">
                Unresolve
              </button>

              <resolver *ngIf="tableData.checkboxCol && tableData.resolver && access()"
                [disabledStatus]="!selectList.length" (onClose)="resolver($event)" class="pull-right ml"></resolver>

              <ng-template [ngIf]="!isEmptyActionKeys">
                <button *ngFor="let action of tableData.actionList; let i = index;" class="pull-right ml"
                  [disabled]="!selectList.length" [ngClass]="action.class" mat-stroked-button color="primary"
                  (click)="doAction(i)">
                  {{ tableData.actionList[i]?.text }}
                </button>
              </ng-template>
            </div>
          </div>
          <div class="row" *ngIf="isSearch">
            <div class="col-sm-12">
              <basic-table-filter [searchFields]="searchFields" [checkboxFilters]="tableData?.checkboxFilters"
                [selectFilters]="tableData?.selectFilters" [liveSearchFilters]="tableData?.liveSearchFilters"
                [where]="where" (onFilter)="onFilter($event)" (onClearFilter)="clearFilter()">
              </basic-table-filter>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="table-responsive" *ngIf="!refreshTable">
            <cdk-virtual-scroll-viewport itemSize="50" minBufferPx="200" maxBufferPx="200"
              class="scroll-viewport" *ngIf="tableData.lazyScroll; else notInfinite" [ngClass]="{
                full: data && data.length > 10,
                medium: data && data.length <= 10 && data.length > 5,
                small: data && data.length <= 5
              }">
              <table class="table">
                <thead>
                  <tr>
                    <th *ngIf="tableData.checkboxCol" class="checkbox-col">
                      <mat-checkbox class="checkbox" color="primary" [ngModel]="allSelect"
                        (ngModelChange)="selectAll()"></mat-checkbox>
                    </th>
                    <ng-template ngFor let-item [ngForOf]="tableData.cols">
                      <th *ngIf="!item?.onlyFilter" [ngClass]="getThClasses(item)" (click)="onSort(item)">
                        {{ item.alias || item.key }}
                        <mat-icon class="sort-icon" *ngIf="item.sort">import_export</mat-icon>
                      </th>
                    </ng-template>
                    <th *ngIf="actionKeys && actionKeys?.length">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *cdkVirtualFor="let entry of data; templateCacheSize: 0" (onToggle)="onToggle($event)" [select]="allSelect"
                    [basicTableRow]="entry" [checkboxCol]="tableData.checkboxCol" [cols]="tableData.cols"
                    [actionKeys]="actionKeys" [actions]="actions" (click)="linkEntry(entry)"></tr>
                </tbody>
              </table>
            </cdk-virtual-scroll-viewport>
            <ng-template #notInfinite>
              <table class="table" *ngIf="isShowTable()">
                <thead>
                  <tr>
                    <th *ngIf="tableData.checkboxCol" class="checkbox-col">
                      <mat-checkbox class="checkbox" color="primary" [ngModel]="allSelect"
                        (ngModelChange)="selectAll()"></mat-checkbox>
                    </th>
                    <ng-template ngFor let-item [ngForOf]="tableData.cols">
                      <th *ngIf="!item?.onlyFilter" [ngClass]="getThClasses(item)" (click)="onSort(item)">
                        {{ item.alias || item.key }}
                        <mat-icon class="sort-icon" *ngIf="item.sort">import_export</mat-icon>
                      </th>
                    </ng-template>
                    <th *ngIf="actionKeys && actionKeys?.length">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let entry of data" (onToggle)="onToggle($event)" [select]="allSelect"
                    [basicTableRow]="entry" [checkboxCol]="tableData.checkboxCol" [cols]="tableData.cols"
                    [actionKeys]="actionKeys" [actions]="actions" (click)="linkEntry(entry)"></tr>
                </tbody>
              </table>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row bottom-info-row" *ngIf="data && !dataNotFound">
    <div class="col-sm-6" *ngIf="!pureMode">
      <div class="pages-container">
        <span>Page: </span>
        <span class="info-data">{{ ((skip/limit) + 1) }}</span> of
        <span class="info-data">{{ total | totalPages:limit }}</span>
        |
        <span>Total items: </span>
        <span class="info-data">{{ total }}</span>
      </div>
    </div>
    <div class="col-sm-6" *ngIf="!pureMode">
      <basic-table-paginator [total]="total" [skip]="skip" [limit]="limit" (onPrev)="prev()" (onNext)="next()">
      </basic-table-paginator>
    </div>
  </div>
  <div class="row" *ngIf="dataNotFound">
    <div class="col-sm-12">
      <hr>
      <h2 class="text-center">Data not found</h2>
      <hr>
    </div>
  </div>
</div>
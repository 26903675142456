import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { CopyServiceComponent } from './copy-service.component'
import { ClipboardModule } from 'ngx-clipboard'

@NgModule({
  imports: [
    CommonModule,
    ClipboardModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [CopyServiceComponent],
  exports: [CopyServiceComponent]
})

export class CopyServiceModule {}

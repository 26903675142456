import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { environment } from '../../../environments/environment'

@Pipe({
  name: 'websiteUrlPreview'
})
export class WebsitePreviewUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(subdomain: string): SafeResourceUrl {
    global.console.log(`https://${subdomain}.${environment.WEBLIUM_SITE}`)
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://${subdomain}.${environment.WEBLIUM_SITE}`)
  }
}

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgaModule } from '../../../theme/nga.module'
import { OrganizationInfoCardComponent } from './organization-info-card.component'

@NgModule({
  declarations: [OrganizationInfoCardComponent],
  imports: [CommonModule, NgaModule],
  exports: [OrganizationInfoCardComponent],
})

export class OrganizationInfoCardModule { }

<div class="row" *ngIf="resource">
  <div class="col-lg-3" [@leftSlide]>
    <ba-card cardTitle="Preview">
      <div class="row">
        <div class="col-sm-12">
          <a [href]="resource?.url" target="_blank" class="preview_image">
            <div class="img-cover">
              <img [src]="resource?.alias?.thumbnail || resource?.alias?.optimized || resource?.url | protocolChecker" alt="..." class="img-fluid" />
            </div>
          </a>
        </div>
      </div>
    </ba-card>
  </div>
  <div class="col-lg-9" [@rightSlide]>
    <div class="row">
      <div class="col-sm-12">
        <ba-card [json]="resource" cardTitle="Info">
          <div class="row">
            <div class="col-sm-6 card__list">
              <div class="card__list__item m-t-b m-t-n" *ngIf="resource?.name">
                <div class="card__list__key">
                  <strong>NAME: </strong>
                </div>
                <div class="card__list__value">
                  <span>{{ resource.name }}</span>
                </div>
              </div>

              <div class="card__list__item m-t-b">
                <div class="card__list__key">
                  <strong>SIZE: </strong>
                </div>
                <div class="card__list__value">
                  <span>{{ resource.props.bytes/1024/1024 | number:'.2' }} MB</span>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="card__list__item m-t-b m-t-n" *ngIf="resource?.owner?.id">
                <div class="card__list__key">
                  <strong>OWNER: </strong>
                </div>
                <div class="card__list__value">
                  <span>{{ resource?.owner?.id }}</span>
                </div>
              </div>

              <div class="card__list__item m-t-b">
                <div class="card__list__key">
                  <strong>DIMENSION: </strong>
                </div>
                <div class="card__list__value">
                  <span>{{ resource?.props?.size?.width }} x {{ resource?.props?.size?.height }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="resource?.description">
            <div class="col-sm-12">
              <strong>DESCRIPTION: </strong>
              <span>{{ resource?.description }}</span>
            </div>
          </div>
        </ba-card>
      </div>
    </div>

    <div class="row" *ngIf="hasTags">
      <div class="col-sm-12">
        <ba-card class="tags-card" cardTitle="Tags">
          <span *ngFor="let tag of resource?.tags">{{ tag }}</span>
        </ba-card>
      </div>
    </div>

    <div class="row" *ngIf="resource?.roles?.length">
      <div class="col-sm-12">
        <ba-card class="tags-card" cardTitle="Roles">
          <span *ngFor="let role of resource?.roles">{{ role }}</span>
        </ba-card>
      </div>
    </div>

    <div class="row m-b">
      <button mat-fab class="btn-edit" color="primary" type="button" (click)="isEdit = !isEdit">
        <mat-icon aria-label="edit">edit</mat-icon>
      </button>
    </div>

    <div class="row" *ngIf="resource && isEdit" [@leftSlide]>
      <div class="col-sm-12">
        <gallery-resource-edit [resource]="resource" [moduleConfig]="moduleConfig"></gallery-resource-edit>
      </div>
    </div>
  </div>
</div>
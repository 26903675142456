import { NgModule, ModuleWithProviders } from '@angular/core'
import { PrettyJsonModule } from 'angular2-prettyjson'
import { ClipboardModule } from 'ngx-clipboard'
import { BaThemeConfig } from './theme.config'
import { BaThemeConfigProvider } from './theme.configProvider'
import { BaCardComponent } from './components'
import { BaThemeRunDirective, VarDirective } from './directives'
import { BaCardBlur } from './components/ba-card/ba-card-blur.directive'
import { BaDateFormatPipe, BaLinkPipe, CapitalizePipe } from './pipes'
import { BaImageLoaderService, BaThemePreloader } from './services'
import { AppStorage } from '../share/services/app-storage.service'
import { CommonModule } from '@angular/common'
import { ClipboardService } from 'ngx-clipboard'
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatTooltipModule } from '@angular/material/tooltip'
import { UserRoleLablePipe } from './pipes/user-role-lable.pipe'
import { WebsitePreviewUrlPipe, PingMessagePipe, ProtocolPipe, CamelCaseToTitlePipe } from '../share/pipes'
import { ProtocolCheckerPipe, UserRolePipe } from './pipes'
import { RouterModule } from '@angular/router'
import { UsdToCentsPipe } from '../share/pipes/usd-to-cents.pipe'
import { CentsToUsdPipe } from '../share/pipes/cents-to-usd.pipe'

const IMPORTS = [
  RouterModule,
  MatMenuModule,
  MatTooltipModule,
  MatIconModule,
  MatButtonModule,
  CommonModule,
  PrettyJsonModule,
  ClipboardModule
]

const NGA_COMPONENTS = [
  BaCardComponent
]

const NGA_DIRECTIVES = [
  BaThemeRunDirective,
  BaCardBlur,
  VarDirective
]

const NGA_PIPES = [
  CamelCaseToTitlePipe,
  ProtocolPipe,
  UserRolePipe,
  ProtocolCheckerPipe,
  PingMessagePipe,
  UserRoleLablePipe,
  BaDateFormatPipe,
  BaLinkPipe,
  CapitalizePipe,
  UsdToCentsPipe,
  CentsToUsdPipe,
  WebsitePreviewUrlPipe
]

const NGA_SERVICES = [
  BaImageLoaderService,
  BaThemePreloader,
  AppStorage,
  ClipboardService,
]

@NgModule({
  declarations: [
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
    ...NGA_COMPONENTS
  ],
  imports: [...IMPORTS],
  providers: [
    UserRoleLablePipe,
    BaThemeConfigProvider,
    BaThemeConfig,
    ...NGA_SERVICES
  ],
  exports: [
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
    ...NGA_COMPONENTS
  ]
})
export class NgaModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: NgaModule,
      providers: [
        BaThemeConfigProvider,
        BaThemeConfig,
        ...NGA_SERVICES
      ],
    }
  }
}

import {
  NgModule,
  ModuleWithProviders
} from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  NotificationsComponent,
  NotificationComponent
} from './components'
import { NotificationService } from './notification.service'

const COMPONENTS = [
  NotificationsComponent,
  NotificationComponent
]

@NgModule({
  imports: [CommonModule],
  providers: [NotificationService],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS]
})

export class NotificationModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: NotificationModule,
      providers: [NotificationService]
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'protocolChecker' })

export class ProtocolCheckerPipe implements PipeTransform {
  transform(value: string): string {
    if (/^(http:\/\/\.|https:\/\/\.|http:\/\/|https:\/\/)/.test(value)
    ) {
      return value
    }
    return location.protocol + value
  }
}

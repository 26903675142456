import { SocketIoService } from './socket-io.service'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { Website } from '../models/website.model'
import { map, mergeMap, switchMap } from 'rxjs/operators'
import { AppHttpService } from './app-http.service'
import { filter } from 'rxjs/operators'
import { get as _get } from 'lodash'

@Injectable()
export class WebsiteService {
  websiteStream = new Subject<any>()
  websiteStream$ = this.websiteStream.asObservable()

  publishStatus = new Subject<any>()
  publishStatus$ = this.publishStatus.asObservable()

  constructor(
    private socket: SocketIoService,
    private appHttpService: AppHttpService) { }

  publishSubsribe(id: string): Observable<any> {
    return this.socket.subscribe$
      .pipe(filter((res: any) =>
        (_get(res, 'worker') === 'SiteRender' || _get(res, 'action') === 'website.publish')
        && _get(res, 'website') === id))
  }

  zip(url: string) {
    return this.appHttpService.getStream(url)
  }

  unzip(websiteId: string, ownerId: string, data: any) {
    return this.appHttpService.setStream(`website/unzip?website=${websiteId}&empty=1&owner=${ownerId}`, data)
  }

  invalidate(id: string): Observable<any> {
    return this.socket.emit('offWebsite.invalidate', { id }).pipe(map((res: any) => res.data))
  }

  refreshDistribution(id: string): Observable<any> {
    return this.socket.emit('offWebsite.refreshDistribution', { id }).pipe(map((res: any) => res.data))
  }

  isCloudFrontEnabled(id: string): Observable<any> {
    return this.socket.emit('offWebsite.isCloudFrontEnabled', { id }).pipe(map((res: any) => res.data))
  }

  cloudFrontEnable(id: string): Observable<any> {
    return this.socket.emit('offWebsite.cloudFrontEnable', { id }).pipe(map((res: any) => res.data))
  }

  cloudFrontDisable(id: string): Observable<any> {
    return this.socket.emit('offWebsite.cloudFrontDisable', { id }).pipe(map((res: any) => res.data))
  }

  restoreRemoved(id: string): Observable<any> {
    return this.socket.emit('offWebsite.restoreRemoved', { id }).pipe(map((res: any) => res.data))
  }

  add(data: any): Observable<any> {
    return this.socket.emit('offWebsite.add', data).pipe(map((res: any) => res.data))
  }

  toggleTemplate(id: string): Observable<any> {
    return this.socket.emit('offWebsite.toggleTemplate', { id })
  }

  toggleOpenaiTemplate(id: string): Observable<any> {
    return this.socket.emit('offWebsite.toggleOpenaiTemplate', { id })
  }

  planList(id: string): Observable<any> {
    return this.socket.emit('offWebsite.planList', { id }).pipe(map((res: any) => res.data))
  }

  toggleScam(id: string): Observable<any> {
    return this.socket.emit('offWebsite.toggleScam', { id })
  }

  togglePro(id: string): Observable<any> {
    return this.socket.emit('offWebsite.togglePro', { id })
  }

  publishAsTemplate(id: string): Observable<any> {
    return this.publish(id)
      .pipe(switchMap(() => this.socket.emit('offWebsite.publishAsTemplate', { id }).pipe(
        map(response => response.data),
        mergeMap(template => this.publish(template._id))
      )))
  }

  unpublishAsTemplate(id: string): Observable<any> {
    return this.socket.emit('offWebsite.unpublishAsTemplate', { id })
  }

  makeScreenshot(_id: string): Observable<any> {
    return this.socket.emit('offWebsite.screenshot', { query: { filter: { _id } } })
  }

  restoreFromHistory(websiteId: string): Observable<any> {
    return this.socket.emit('offWebsite.restoreFromHistory', { id: websiteId })
  }

  makeHistory(id: string): Observable<any> {
    return this.socket.emit('offWebsite.makeHistory', { id })
  }

  get(_id: string): Observable<any> {
    return this.socket.emit('offWebsite.get', { _id })
      .pipe(map((response: any) => <Website>Website.fromJSON(response.data)))
  }

  getBySubdomain(subdomain: string, select = 'subdomain'): Observable<any> {
    return this.socket.emit('offWebsite.getBySubdomain', { subdomain, query: { select } })
      .pipe(map((response: any) => response.data))
  }

  suspend(id: string, comment: string): Observable<any> {
    return this.socket.emit('offWebsite.suspend', { id, data: {comment} }).pipe(map((res: any) => res.data))
  }

  resume(id: string,comment: string): Observable<any> {
    return this.socket.emit('offWebsite.resume', { id, data: {comment} }).pipe(map((res: any) => res.data))
  }

  employeeCommentRemove(filter: object) {
    return this.socket.emit('offWebsite.employeeCommentRemove', { data: filter }).pipe(map((res: any) => res.data))
  }

  employeeCommentUpdate(id: string, text: string) {
    return this.socket.emit('offWebsite.employeeCommentUpdate', { id, data: {text} }).pipe(map((res: any) => res.data))
  }

  employeeCommentSuspendAdd(id: string, text: string) {
    return this.socket.emit('offWebsite.employeeCommentSuspendAdd', { id, data: {text} }).pipe(map((res: any) => res.data))
  }

  startMaintenance(id: string): Observable<any> {
    return this.socket.emit('offWebsite.startMaintenance', { id })
      .pipe(map((res: any) => res.data))
  }

  endMaintenance(id: string): Observable<any> {
    return this.socket.emit('offWebsite.endMaintenance', { id })
      .pipe(map((res: any) => res.data))
  }

  remove(_id: string): Observable<any> {
    return this.socket.emit('offWebsite.remove', { _id })
  }

  checkSubdomain(subdomain: string): Observable<any> {
    return this.socket.emit('offWebsite.checkSubdomain', { subdomain })
      .pipe(map((res: any) => res.data))
  }

  list(data: any): Observable<any> {
    return this.socket.emit('offWebsite.list', data)
      .pipe(map((res: any) => res.data))
  }

  history(data: any): Observable<any> {
    return this.socket.emit('offWebsite.history', data)
      .pipe(map((res: any) => res.data))
  }

  addContributors(id: string, contributors: string[]): Observable<any> {
    return this.socket.emit('offWebsite.addContributors', {id, data: {contributors}})
      .pipe(map((res: any) => res.data))
  }

  update(id: string, data: any): Observable<any> {
    return this.socket.emit('offWebsite.update', { id, data })
      .pipe(map((res: any) => res.data && res.data || res))
  }

  updateRibbon(id: string, data: any): Observable<any> {
    return this.socket.emit('offWebsite.updateRibbon', { id, data })
      .pipe(map((res: any) => res.data && res.data || res))
  }

  removeRibbon(id: string): Observable<any> {
    return this.socket.emit('offWebsite.removeRibbon', { id }).pipe(map((res: any) => res.data && res.data || res))
  }

  removeRenderTarget(id: string, data: any): Observable<any> {
    return this.socket.emit('offWebsite.removeRenderTarget', { id, data })
      .pipe(map((res: any) => res.data && res.data || res))
  }

  updateCategories(category: string, list: string[]): Observable<any> {
    return this.socket.emit('offWebsite.updateCategories', {
      category,
      query: {
        filter: {
          _id: {
            $in: list
          }
        }
      }
    }).pipe(map((response: any) => response.data))
  }

  removeCategories(category: string, list: string[]): Observable<any> {
    return this.socket.emit('offWebsite.removeCategories', {
      category,
      query: {
        filter: {
          _id: {
            $in: list
          }
        }
      }
    }).pipe(map((response: any) => response.data))
  }

  changeOwner({ id, owner, data }: any): Observable<any> {
    const requestData = { id, owner },
      query = data ? Object.assign(requestData, { data }) : requestData

    return this.socket.emit('offWebsite.changeOwner', { ...query })
      .pipe(map((res: any) => res.data))
  }

  websiteSelectedDel(list: string[]): Observable<any> {
    return this.socket.emit(
      'offWebsite.removeMany',
      {
        data: {
          _id: {
            $in: list
          }
        }
      }).pipe(map((res: any) => res.data))
  }

  getHomePage(websiteId: string, select = '_id'): Observable<{ _id: string }> {
    return this.socket.emit('offWebsite.getHomePage', { id: websiteId, query: { select } })
      .pipe(map((res: any) => res.data))
  }

  relinkDomain(id: string): Observable<any> {
    return this.socket.emit('offWebsite.linkDomain', { id })
      .pipe(map((res: any) => res.data))
  }

  publish(id: string): Observable<any> {
    return this.socket.emit('offWebsite.publish', { id })
      .pipe(map((res: any) => res.data))
  }

  removePage({ pageId, id, __v }: { pageId: string, id: string, __v: number }): Observable<any> {
    return this.socket.emit('offWebsitePage.remove', {
      pageId,
      id,
      __v
    }).pipe(map((res: any) => res.data))
  }

  // render(id: string): Observable<any> {
  //   return this.socket.emit('offWebsite.render', { id })
  //     .pipe(map((res: any) => res.data))
  // }

  createFromToUser({ id, user, name }: any): Observable<any> {
    return this.socket.emit('offWebsite.createFromToUser', {
      id,
      data: { user, name }
    }).pipe(map((res: any) => res.data))
  }

  transfer(data: any): Observable<any> {
    return this.socket.emit('offWebsite.transferToUser', data)
      .pipe(map((res: any) => res.data))
  }

  copy({ from, to }: any): Observable<any> {
    return this.socket.emit('offWebsite.copy', { from, to }).pipe(map((res: any) => res.data))
  }

  assignPaymentLink({ structure: { __v, _id: id }, data }: { structure: Website, data: Object }): Observable<any> {
    return this.socket.emit('offWebsite.assignPaymentLink', {
      id, data: {
        $set: { 'options.paymentLink': data },
        __v
      }
    })
      .pipe(map((response: { data: any }) => response.data))
  }

  searchWebsites(query: any) {
    return this.socket.emit('offWebsite.list', { query }).pipe(map((res: any) => res.data))
  }

  assertDistribution(data: any) {
    return this.socket.emit('offWebsite.assertDistribution', data).pipe(map((res: any) => res.data))
  }

  removeDistribution(websiteId: string) {
    return this.socket.emit('offWebsite.removeDistribution', { websiteId }).pipe(map((res: any) => res.data))
  }

  listWebsites({ $regex, id, filter }: { $regex: string, id?: string, filter?: any }): Observable<Website[]> {
    const find = { $regex, $options: 'i' }
    const query: any = {
      limit: 5,
      sort: { _id: -1 },
      filter: {
        ...filter,
        history: null
      }
    }

    id && (query.filter._id = { $ne: id })

    if (/^(.{12}|[0-9a-fA-F]{24})$/.test($regex)) {
      query.filter._id = $regex
    } else {
      query.filter.$or = []
      query.filter.$or.push({ subdomain: find }, { name: find })
    }

    return this.socket.emit('offWebsite.list', { query })
      .pipe(map((res: any) => res.data))
  }

  childList(id: string): Observable<any> {
    const query: any = {
      filter: {
        source: id,
        history: null
      },
      select: 'source'
    }
    return this.socket.emit('offWebsite.list', { query })
  }

  checkFtpConnect(url: string): Observable<any> {
    return this.socket.emit('offWebsite.checkFtpConnect', { data: { url } })
  }

  subscribe2support(id: string, data: any): Observable<any> {
    return this.socket.emit('offWebsite.subscribe2support', {
      id, data: { ...data }, query: {
        link: true, ...({
          processor: ['stripe', 'paypal'].includes(data.paymentSystem) && 'tm'
        })
      },
    }).pipe(map((response: any) => response.data))
  }

  setHomePage(id: string, pageId: string): Observable<any> {
    return this.socket.emit('offWebsite.setHomePage', { id, pageId })
  }

  getSubscription(_id: string): Observable<any> {
    return this.socket.emit('offWebsite.getSubscription', { _id })
      .pipe(map((response: any) => response.data))
  }

  createCommentToken(id: string): Observable<any> {
    return this.socket.emit('offWebsite.createCommentToken', { id })
      .pipe(map((response: any) => response.data))
  }

  staticToggle(_id: string, value: boolean): Observable<any> {
    return this.socket.emit(value ? 'offWebsite.staticEnable' : 'offWebsite.staticDisable', { _id })
      .pipe(map((res: any) => res.data))
  }

  staticRemove(_id: string): Observable<any> {
    return this.socket.emit('offWebsite.staticRemove', { _id })
      .pipe(map((res: any) => res.data))
  }

  setSubscription(_id: string, subscriptionId: string): Observable<any> {
    return this.socket.emit('offWebsite.setSubscription', { _id, subscriptionId })
      .pipe(map((res: any) => res.data))
  }

  staticUpload(id: string, file: File): Observable<any> {
    return this.appHttpService.setStream(`off_website/${id}/upload_static`, file)
  }
}

import { Component, Input, Output, AfterContentInit, EventEmitter } from '@angular/core'
import { isEmpty as _isEmpty } from 'lodash'

@Component({
  selector: '[basicTableRow]',
  templateUrl: 'basic-table-row.component.html',
  styleUrls: ['basic-table-row.component.scss'],
})

export class BasicTableRowComponent implements AfterContentInit {
  @Output() onToggle = new EventEmitter<any>()
  @Input() basicTableRow: any
  @Input() cols: any[]
  @Input() actions: any
  @Input() actionKeys: string[]
  @Input() checkboxCol: boolean
  @Input() select: boolean

  filteredActions: string[]

  constructor() { }

  ngAfterContentInit() {
    this.filteredActions = this.actionKeys
      .filter((i: any) => typeof this.actions[i].condition === 'function'
        ? this.actions[i].condition(this.basicTableRow)
        : true)
  }

  toggle(item: any): void {
    this.select = !this.select
    this.onToggle.emit(item)
  }

  trackByFn(index: number): number { return index }

  doAction(action: any) {
    this.actions[action].handle && this.actions[action].handle(this.basicTableRow)
  }

  get isEmptyActionKeys() {
    return _isEmpty(this.actionKeys)
  }
}

export class Env {
  production?: boolean
  BO_API_URL?: string
  APP_VERSION?: string
  ANGULAR_VERSION?: string
  CHARGEBEE_APP?: string
  WEBLIUM_DOMAIN?: string
  WEBLIUM_SITE?: string
  STRUCTURE_DOMAIN?: string
  STRUCTURE_SITE?: string
  ORGANIZATION_SITE?: string
  GOOGLE_MAP_KEY?: string
  HOST?: string
  PORT?: string
  API_HOST?: string
  API_PORT?: string
  EDITOR_PORT?: string
  API_URL?: string
  EDITOR_URL?: string
  BO_URL?: string
  WS_HOST?: string
  BUCKET_URL?: string
  SHOW_LOG?: boolean
  SHOW_SUBSCRIBES_LOG?: boolean
}

export default function getEnvs({
  ORGANIZATION_SITE = 'yourwebsite.life',
  CHARGEBEE_APP = 'webliumtest-test',
  WEBLIUM_DOMAIN = 'weblium.io',
  WEBLIUM_SITE = WEBLIUM_DOMAIN,
  STRUCTURE_DOMAIN = 'draftium.io',
  STRUCTURE_SITE = WEBLIUM_DOMAIN,
  HOST = `backoffice.${WEBLIUM_DOMAIN}`,
  ...envs }: Env = {}): Env {
  return {
    ...{
      production: false,
      SHOW_LOG: true,
      SHOW_SUBSCRIBES_LOG: false,
      APP_VERSION: require('../../package.json').version,
      ANGULAR_VERSION: require('../../package.json').dependencies['@angular/core'].match(/([\d\.]+)/)[0],
      CHARGEBEE_APP,
      ORGANIZATION_SITE,
      WEBLIUM_DOMAIN,
      WEBLIUM_SITE,
      STRUCTURE_DOMAIN,
      STRUCTURE_SITE,
      GOOGLE_MAP_KEY: 'AIzaSyBrp8atDpxn3Hsf7jytunkbDimAZyGmjno',
      HOST,
      BO_API_URL: HOST == 'localhost' ? `http://${HOST}:3005` : `https://admin.${WEBLIUM_DOMAIN}`,
      API_URL: HOST == 'localhost' ? `http://${HOST}:3000` : `https://api.${WEBLIUM_DOMAIN}`,
      API_HOST: HOST == 'localhost' ? `${HOST}:3000` : `api.${WEBLIUM_DOMAIN}`,
      BO_URL: HOST == 'localhost' ? `http://${HOST}:4200` : `https://${HOST}`,
      BUCKET_URL: 'res2.weblium.io',
    }, ...envs
  }
}

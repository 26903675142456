import { SocketIoService } from './socket-io.service'
import { Observable, throwError } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HandleError } from '../handle-error'
import { get as _get, omit as _omit } from 'lodash'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../environments/environment'

@Injectable()
export class AppHttpService {

  private urls: any = {
    json: `${environment}/json`
  }

  constructor(
    private socket: SocketIoService,
    private http: HttpClient) {
  }

  get(url: string): Observable<any> {
    return this.http.get(url)
  }

  postJson(data: any, needAuth = true) {
    return needAuth
      ? this.makeAuthRequest(data)
      : this.makeRequest(data)
  }

  getStream(url: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.socket.api.token}`,
    })
    return this.http.get(`${environment.BO_API_URL}/api/${url}`, { headers, responseType: 'blob' })
  }

  postStream(data: any): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.socket.api.token}`,
    })
    return this.http.post(`${environment.BO_API_URL}/api/json`,
      data, { headers, responseType: 'blob' })
  }

  postJSONStream(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': `Bearer ${this.socket.api.token}` })
    return this.http.post(`${environment.BO_API_URL}/api/json`, data, { headers, responseType: 'json' })
      .pipe(map((response: any) => ({ data: response, total: (response || []).length, skip: 0 })))
  }

  setStream(url: any, data: any, query?: string) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.socket.api.token}`,
    })
    const blob = new Blob([data])
    let _url = `${environment.BO_API_URL}/api/${url}`
    query && (_url = `${_url}?${query}`)

    return this.http.post(_url, blob, { headers })
  }

  uploadFile(url: any, fileToUpload: File, query?: string) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.socket.api.token}`,
    })


    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);

    let _url = `${environment.BO_API_URL}/api/${url}`
    query && (_url = `${_url}?${query}`)

    return this.http.post(_url, formData, { headers })
  }

  private getRequestOptions(): { headers: HttpHeaders } {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    return { headers }
  }

  private getAuthRequestOptions(): { headers: HttpHeaders } {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', 'Authorization': `Bearer ${this.socket.api.token}`
    })
    return { headers }
  }

  private makeAuthRequest(data: any) {
    return this.http
      .post(`${this.urls.json}/${data.action}`, _omit(data, ['action']),
        this.getAuthRequestOptions()
      )
      .pipe(
        map((response: any) => response.json()),
        catchError((error: any) => this.handleError(error))
      )
  }

  private makeRequest(data: any) {
    return this.http.post(`${this.urls.json}/${data.action}`, _omit(data, ['action']), this.getRequestOptions())
      .pipe(
        map((response: any) => response.json()),
        catchError((error: any) => this.handleError(error))
      )
  }

  private handleError(response: Response) {
    switch (response.status) {
      case 0:
        throw new Error('Connection wrong')
      case 401:
        throw new Error('Invalid username or password!')
      default:
        const resp = response.json()
        const err: any = _get(resp, 'error', resp)
        if (err.code === 'validation') {
          err && !err.message && (err.message = HandleError.getValidationMsg(_get(err, 'errors[0]', err)))
        } else {
          const msgByCode = HandleError.getMsgByCode(err.code) || err.message || 'Some error'
          err.code && (err.message = msgByCode)
        }
        return throwError(err)
    }
  }
}

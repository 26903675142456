import { Component, OnInit } from '@angular/core'
import { GlobalState } from '../../../global.state'
import { isNull as _isNull } from 'lodash'

@Component({
  selector: 'ba-content-top',
  templateUrl: 'ba-content-top.html',
  styleUrls: ['ba-content-top.scss'],
})
export class BaContentTopComponent implements OnInit {
  public activePage: any

  constructor(private _state: GlobalState) {
  }

  ngOnInit(): void {
    this.activePage = null
    this._state.subscribe('menu.activeLink',
      (activePage: any) => {
        if (!_isNull(activePage)) {
          this.activePage = activePage
        } else {
          this.activePage = null
        }
      })
  }
}

import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { NotificationActions } from './notification-actions'
import { IConfigNotification } from './share/i-config-notification'

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private actionStream = new Subject<NotificationActions>()
  actionStream$ = this.actionStream.asObservable()

  private defaultNotify: IConfigNotification = {
    type: 'error',
    state: 'fromRight',
    progressBar: true,
    duration: 3000
  }

  constructor() { }

  remove(index: number) {
    this.actionStream.next(<NotificationActions>{ action: 'remove', index })
  }

  add(notify: IConfigNotification) {
    const item = <IConfigNotification>{ ...this.defaultNotify, ...notify }
    this.actionStream.next(<NotificationActions>{ action: 'add', item })
  }

}

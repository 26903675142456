import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class AppStorage {
  private data: any

  static get(key: string): any {
    const data = localStorage.getItem(key)
    try {
      return JSON.parse(data)
    } catch (e) {
      return data
    }
  }

  constructor() {
    this.data = {}
  }

  get(key: string, session = false): any {
    const data = (session ? sessionStorage : localStorage).getItem(key)
    try {
      return JSON.parse(data)
    } catch (e) {
      return data
    }
  }

  clear(key: string, session = false): any {
    (session ? sessionStorage : localStorage).removeItem(key)
  }

  set(key: string, val: any = null, session = false): void {
    this.data[key] = val
    if (typeof val === 'string') {
      (session ? sessionStorage : localStorage).setItem(key, val)
    } else {
      (session ? sessionStorage : localStorage).setItem(key, JSON.stringify(val))
    }
  }
}

import { User } from './user.model'
import { IWebsiteJson } from '../interfaces/website'
import * as moment from 'moment'

export interface PlanOverwrite {
  pageLimit?: number
  langLimit?: number,
  app?: {
    ecommerce?: {
      variantsPerProductLimit?: number, resourceCountLimit?: Number,
      productOptionLimit?: number, draftProductLimit?: number, publishedProductLimit: Number,
    }
  },
}

export class Website {
  published?: {
    at: string;
    by: string;
  }
  cloudFront?: any
  scam?: {
    score?: number
  }
  source?: string
  orgOwner: User
  roles?: string[]
  domainRaw?: string
  render?: any
  preview?: string
  integration?: any
  _id: string
  name: string
  description?: string
  owner: User
  categories?: any[]
  tags?: string[]
  redirects?: Array<{
    path: string,
    target: string,
    status: '301' | '302',
    createdAt: string,
  }>
  proxies?: Array<{
    path: string,
    target: string,
  }>
  history?: any
  domain: {
    _id: string;
    name: string;
    state: string;
  }
  subdomain?: string
  concept?: string
  pages: [{
    metadata: string;
    content: string;
  }]
  seo: {
    title: string;
    description: string;
    robots: string
    keywords: Array<string>
  }
  status: string
  privacyStatus: string
  createdAt: any
  maintenance?: string
  suspended?: {
    at: string;
    by: string;
    error: {
      message: string,
      stack: string
    }
  }
  removed?: {
    at: string;
    by: string;
  }
  createdBy: any
  updatedAt: any
  options: any
  thumbnail?: string
  fullSubdomain?: string
  lastIncome?: number
  subscription: any
  expiredAt?: any
  renderTarget?: string
  organization?: any
  // tslint:disable-next-line
  __v?: number
  static?: {
    enabled?: boolean,
    uid?: string,
    directoryIndex?: string,
    statusMap?: Record<string, string>
  }
  overwrite_plan?: {
    paid?: PlanOverwrite,
    unpaid?: PlanOverwrite,
    trial?: PlanOverwrite
  }
  storageUsed: number
  plan: any

  static fromJSON(json: IWebsiteJson): Website {
    const website = Object.create(Website.prototype)
    return Object.assign(website, json, {
      createdAt: moment(json.createdAt),
      updatedAt: moment(json.updatedAt)
    })
  }

  static reviver(key: string, value: any): any {
    return key === '' ? Website.fromJSON(value) : value
  }

  constructor() {
  }

  toJSON(): IWebsiteJson {
    return Object.assign({}, this, {
      createdAt: this.createdAt.format('YYYY-MM-DD HH:mm:ss'),
      updatedAt: this.updatedAt.format('YYYY-MM-DD HH:mm:ss')
    })
  }
}

import { SocketIoService } from './socket-io.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
// import { Account } from '../models/account.model'
import { AppStorage } from './app-storage.service'
// import { get as _get } from 'lodash'

@Injectable()
export class CountryService {
  constructor(
    private socket: SocketIoService,
    private storage: AppStorage) {
  }

  list(): Observable<any> {
    const countries = this.storage.get('countries', true)
    if (countries) {
      return Observable.create((observer: any) => observer.next(countries))
    } else {
      return this.socket.emit('offCountry.list', {})
        .pipe(
          tap((res: any) => this.storage.set('countries', res.data, true)),
          map((res: any) => res.data)
        )
    }
  }
}

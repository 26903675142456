<td *ngIf="checkboxCol" class="checkbox-col">
  <mat-checkbox *ngIf="basicTableRow.role !== 'admin'"
                class="checkbox"
                color="primary"
                [ngModel]="select"
                (ngModelChange)="toggle(basicTableRow)"
                (click)="$event.stopPropagation()"></mat-checkbox>
</td>

<ng-template ngFor let-col [ngForOf]="cols">
  <td *ngIf="!col?.onlyFilter"
      [basicTableCell]="col"
      [basicTableCellEntry]="basicTableRow"></td>
</ng-template>

<td *ngIf="!isEmptyActionKeys && basicTableRow.role !== 'admin'" class="actions">
  <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <ng-template  ngFor let-action [ngForOf]="filteredActions" [ngForTrackBy]="trackByFn">
      <button mat-menu-item
              (click)="doAction(action)">
        <mat-icon>{{ actions[action]?.icon }}</mat-icon>
        <span>{{ actions[action]?.text }}</span>
      </button>
    </ng-template>
  </mat-menu>
</td>

import { Record } from './../../pages/domains/share/record'
import { SocketIoService } from './socket-io.service'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { PaymentSettings } from '../../pages/settings/components/payment-details/payment-settings'

@Injectable()
export class SettingService {

  constructor(
    private socket: SocketIoService,
    private http: HttpClient
  ) { }

  getSystemSettings(): Observable<any> {
    return this.socket.emit('offSystem.getWebsiteState')
      .pipe(map((res: any) => res.data))
  }

  setSystemSetting(data: any): Observable<any> {
    return this.socket.emit('offSystem.setWebsiteState', { data })
  }

  getTypeForms(): Observable<any> {
    return this.socket.emit('offSetting.getBitrix')
      .pipe(map((res: any) => res.data))
  }

  updateTypeForms(code: string): Observable<any> {
    return this.socket.emit('offSetting.typeformUpdate', { key: 'bitrix', data: { code } })
  }

  testForm(id: string, data: any): Observable<any> {
    return this.http.post(`${environment.BO_API_URL}/typeform/${id}`, data)
  }

  getPipeData(): Observable<any> {
    return this.socket.emit('offSetting.constants',
      {
        query: {
          keys: ['BlockCategoryRoles']
        }
      })
      .pipe(map((res: any) => res.data))
  }

  getPaymentSettings(): Observable<PaymentSettings> {
    return this.socket.emit('offSetting.getRes24')
      .pipe(map((res: any) => res.data))
  }

  setPaymentSettings(data: PaymentSettings): Observable<any> {
    return this.socket.emit('offSetting.res24Update', { data })
      .pipe(map((res: any) => res.data))
  }

  getContacts(provider: any): Observable<any> {
    return this.socket.emit('offSetting.domainProvider', provider)
      .pipe(map((res: any) => res.data))
  }

  updateContacts(provider: any, contactSet: any): Observable<any> {
    return this.socket.emit('offSetting.domainProviderUpdate', { ...provider, data: { contactSet } })
      .pipe(map((res: any) => res.data))
  }

  recordTemplateGet(id?: string): Observable<any> {
    return this.socket.emit('offSetting.getRecordTemplates', { id })
      .pipe(map((res: any) => res.data))
  }

  recordTemplateAdd(name: string): Observable<any> {
    return this.socket.emit('offSetting.addRecordTemplate', { data: { name } })
      .pipe(map((res: any) => res.data))
  }

  recordTemplateUpdate({ _id, name, records, host }
    : { _id: string, name: string, records: Record[], host: number }): Observable<any> {
    return this.socket.emit('offSetting.updateRecordTemplate', { id: _id, data: { name, records, host } })
      .pipe(map((res: any) => res.data))
  }

  recordTemplateRemove(ids: string[]): Observable<any> {
    return this.socket.emit('offSetting.removeRecordTemplate', { data: { ids } })
      .pipe(map((res: any) => res.data))
  }
}

import { map } from 'rxjs/operators'
import { SocketIoService } from '../../share/services/socket-io.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Block } from '../interfaces/block'
import { AppHttpService } from './app-http.service'

@Injectable()
export class BlockService {
  constructor(private socket: SocketIoService, private appHttpService: AppHttpService) { }

  download(url: string) {
    return this.appHttpService.getStream(url)
  }

  upload(blockId: string, ownerId: string, data: any) {
    return this.appHttpService.setStream(`block/unzip?block=${blockId}&owner=${ownerId}`, data)
  }

  get(id: string): Observable<Block> {
    return this.socket.emit('offBlock.get', { id })
      .pipe(map((res: any) => res.data))
  }

  togglePopup(id: string): Observable<Block> {
    return this.socket.emit('offBlock.togglePopup', { id })
      .pipe(map((res: any) => res.data))
  }

  update(data: any): Observable<any> {
    return this.socket.emit('offBlock.update', { ...data })
      .pipe(map((res: any) => res.data))
  }

  publish(id: string): Observable<any> {
    return this.socket.emit('offBlock.publish', { id })
      .pipe(map((res: any) => res.data))
  }

  unpublish(id: string): Observable<any> {
    return this.socket.emit('offBlock.unpublish', { id })
      .pipe(map((res: any) => res.data))
  }

  delete(id: string): Observable<any> {
    return this.socket.emit('offBlock.remove', { id })
      .pipe(map((res: any) => res.data))
  }

  changeCategory({ filter, remove, append }: any): Observable<any> {
    return this.socket.emit('offBlock.changeCategory', {
      query: { filter, remove, append }
    }).pipe(map((res: any) => res.data))
  }

  upgrade(query: any): Observable<any> {
    return this.socket.emit('offBlock.upgrade', { query })
  }
}

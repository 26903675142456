<div class="row form">
  <div class="col-sm-12">
    <div class="row">
      <div [ngClass]="{
        'col-md-6': dateFields.length > 0,
        'col-md-12': dateFields.length === 0 || textFields.length === 0
      }">
        <div class="row" *ngIf="idField">
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="input-container">
              <mat-label>{{ idField.alias }}</mat-label>
              <input matInput #idInput placeholder="{{ idField.alias }}" [id]="idField.key+'field'" [(ngModel)]="where[idField.key]"
                (keyup.enter)="idInput.value && filter()" type="text">
            </mat-form-field>
          </div>
        </div>

        <div class="row" *ngIf="textFields">
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="input-container" *ngFor="let field of textFields">
              <mat-label>{{ field?.alias }}</mat-label>
              <input matInput [(ngModel)]="where[field?.key]" (keyup)="debounceFilter(where[field?.key], field['key'])"
                [id]="field['key']+'field'" type="text" placeholder="{{ field?.alias }}">
            </mat-form-field>
          </div>
        </div>

        <div class="row" *ngIf="liveSearchFilters">
          <div class="col-sm-12">
            <ng-template ngFor let-key [ngForOf]="liveSearchKeys" [ngForTrackBy]="trackByFn">
              <mat-form-field appearance="outline" class="input-container">
                <mat-label>{{ liveSearchFilters[key]?.placeholder }}</mat-label>
                <input type="text" placeholder="{{liveSearchFilters[key]?.placeholder}}" matInput #catInput
                  [formControl]="liveSearchControls[key]" [matAutocomplete]="auto">
                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="liveSearchFilters[key]?.displayFn"
                  (optionSelected)="addLiveSearchFilter(key, $event.option.value[liveSearchFilters[key]?.where])">
                  <ng-template ngFor let-item [ngForOf]="liveSearchLists[key]" [ngForTrackBy]="trackByFn">
                    <mat-option [value]="item" [ngStyle]="{'height.px': '50', 'line-height.px': '20'}">
                      <span>{{ liveSearchFilters[key]?.displayFn(item) | titlecase }}</span>
                      <br>
                      <small *ngIf="item[liveSearchFilters[key]?.subtextPath]" [ngStyle]="{'opacity': '.7'}">{{
                        liveSearchFilters[key]?.subtextFilter(item) }}</small>
                    </mat-option>
                  </ng-template>
                </mat-autocomplete>
              </mat-form-field>
            </ng-template>
          </div>
        </div>

        <div class="row" *ngIf="aheadFields">
          <div class="col-sm-12">
            <ng-template ngFor let-field [ngForOf]="aheadFields" [ngForTrackBy]="trackByFn">
              <mat-form-field appearance="outline" class="input-container">
                <mat-label>{{field?.alias}}</mat-label>
                <input type="text" placeholder="{{field?.alias}}" matInput [ngModel]="field?.value" (ngModelChange)="onChangeAhead(field['key'], $event, field)"
                  [matAutocomplete]="auto">

                <mat-autocomplete #auto="matAutocomplete">
                  <ng-template ngFor let-option [ngForOf]="(field?.filter?.list | aheadList:field?.value)"
                    [ngForTrackBy]="trackByFn">
                    <mat-option (onSelectionChange)="where[field.key] = option?.action" [value]="option.value">
                      {{ option.value }}
                    </mat-option>
                  </ng-template>
                </mat-autocomplete>
              </mat-form-field>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="date-fields" [ngClass]="{
            'col-md-6': textFields.length > 0,
            'col-md-12': textFields.length === 0 || textFields.length === 0 }"
        *ngIf="dateFields">
        <div class="row">
          <ng-template ngFor let-field [ngForOf]="dateFields">
            <div class="col-sm-6">
              <mat-form-field appearance="outline" class="input-container">
                <mat-label>{{field.alias + ' from'}}</mat-label>
                <input #from matInput [id]="field['key'] + '-from'" [ngModel]="where[field.key]?.$gt | date: 'yyyy-MM-dd HH:mm'"
                  (ngModelChange)="onDateChanged($event, field, '$gt')" [value]="where[field.key]?.$gt | date: 'yyyy-MM-dd HH:mm'"
                  placeholder="{{ field.alias + ' from' }}" readonly />
                <mat-icon matSuffix *ngIf="where[field.key]?.$gt" color="warn" class="clear" (click)="clearInput(field.key, '$gt'); $event.stopPropagation()">
                  clear
                </mat-icon>
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field appearance="outline" class="input-container">
                <mat-label>{{field.alias + ' to'}}</mat-label>
                <input #to matInput [id]="field['key'] + '-to'" [ngModel]="where[field.key]?.$lt | date: 'yyyy-MM-dd HH:mm'"
                  (ngModelChange)="onDateChanged($event, field, '$lt')" [value]="where[field.key]?.$lt | date: 'yyyy-MM-dd HH:mm'"
                  placeholder="{{ field.alias + ' to' }}" readonly />
                <mat-icon matSuffix *ngIf="where[field.key]?.$lt" color="warn" class="clear" (click)="clearInput(field.key, '$lt'); $event.stopPropagation()">
                  clear
                </mat-icon>
              </mat-form-field>
            </div>
          </ng-template>
        </div>
        <div class="row" *ngIf="selectFields">
          <div class="col-sm-12">
            <ng-template ngFor let-field [ngForOf]="selectFields" [ngForTrackBy]="trackByFn">
              <ng-template [ngIf]="field?.filter?.list">
                <mat-form-field appearance="outline" class="input-container">
                  <mat-label>{{field.alias}}</mat-label>
                  <mat-select placeholder="{{field.alias}}" id="select_{{field.key}}" [ngModel]="where[field.key]"
                    (ngModelChange)="changeSelect($event, field)">
                    <ng-template ngFor let-item [ngForOf]="field?.filter?.list" [ngForTrackBy]="trackByFn">
                      <mat-option [value]="item.key">
                        {{ item.alias }}
                      </mat-option>
                    </ng-template>
                  </mat-select>
                </mat-form-field>
              </ng-template>
            </ng-template>
          </div>
        </div>
        <div class="row" *ngIf="selectFilters">
          <div class="col-sm-12">
            <ng-template ngFor let-select [ngForOf]="selectFilters" [ngForTrackBy]="trackByFn">
              <mat-form-field appearance="outline" class="input-container" *ngIf="select?.options">
                <mat-label>{{select.alias}}</mat-label>
                <mat-select placeholder="{{select.alias}}" [value]="selectFilterValue(select)" (selectionChange)="changeSelectFilter($event, select)">
                  <ng-template ngFor let-item [ngForOf]="select?.options" [ngForTrackBy]="trackByFn">
                    <mat-option [value]="item.key">{{ item.label }}</mat-option>
                  </ng-template>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="row checkbox-container" *ngIf="checkboxFilters">
      <div class="col-md-12">
        <mat-checkbox *ngFor="let key of getCheckboxes()" [ngModel]="checkboxFilters[key].value" (ngModelChange)="changeCheckbox(key, $event)"
          color="primary" class="checkbox">
          {{checkboxFilters[key].label}}
        </mat-checkbox>
      </div>
    </div>

    <div class="row btn-container">
      <div class="col-sm-12">
        <button mat-stroked-button color="primary" class="btn-mm btn-m pull-right" (click)="clearFilter()">
          Clear
        </button>
      </div>
    </div>
  </div>
</div>
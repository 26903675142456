import { Injectable } from '@angular/core'
import { SocketIoService } from '../../services/socket-io.service'
import { Observable, Subject } from 'rxjs'
import { GalleryResource } from './share/gallery-resource'
import { tap } from 'rxjs/operators'

@Injectable()
export class GalleryService {

  private resourceUpdateSource = new Subject<any>()
  resourceShowSource = new Subject<string>()

  constructor(private socket: SocketIoService) { }

  getFiles(action: string, skip: number, limit: number, id?: string):
    Observable<{ data: GalleryResource[]; total: number; skip: number }> {
    return this.socket.emit(
      action,
      Object.assign({ owner: id, query: { limit, skip } }))
  }

  searchFiles(action: string, search: string, skip: number, limit: number):
    Observable<{ data: GalleryResource[]; total: number; skip: number }> {
    return this.socket.emit(action, { query: { search, limit, skip } })
  }

  searchResourceFiles(action: string, search: string, skip = 0, limit: number, id: string):
    Observable<{ data: GalleryResource[]; total: number; skip: number }> {
    return this.socket.emit(action, {
      owner: id,
      query: {
        limit,
        skip,
        search
      }
    })
  }

  getFile(action: string, id: string): Observable<GalleryResource | any> {
    return this.socket.emit(action, { id })
  }

  getFileByName(action: string, name: string, owner: string): Observable<any> {
    return this.socket.emit(action, { owner, query: { search: name, filter: { location } } })
  }

  deleteFile(action: string, id: string, query?: any): Observable<any> {
    return this.socket.emit(action, { id, query })
  }

  updateResource(action: string, id: string, data: any): Observable<any> {
    return this.socket.emit(action, { id, data })
      .pipe(tap((res: any) => this.resourceUpdateSource.next(res)))
  }

  get resourceUpdateStream$() {
    return this.resourceUpdateSource.asObservable()
  }

  get resourceShowSource$() {
    return this.resourceShowSource.asObservable()
  }
}

import { map, first, mergeMap, catchError } from 'rxjs/operators'
import { SocketIoService } from './socket-io.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class ResourceService {

  constructor(private socket: SocketIoService, private httpClient: HttpClient) { }

  count = (): Observable<{ count: number }> => this.socket.emit('offResource.count').pipe(
    map((res: any) => res.data)
  )

  parseImage = ({ res, err }: { res: Blob, err?: boolean }): Observable<any> => Observable.create((observer: any) => {
    const reader = new FileReader()

    reader.readAsDataURL(res)
    reader.addEventListener('load', () => observer.next({ imgUrl: reader.result, err }), false)
  })

  loadDefaultImage = (defaultUrl = 'assets/img/no_image_available.jpeg'): Observable<{ res: Blob, err: boolean }> =>
    this.httpClient.get(defaultUrl, { responseType: 'blob' }).pipe(
      map(res => ({ res, err: true }))
    )

  getImage = (imageUrl: string, defaultUrl?: string): Observable<any> =>
    this.httpClient.get(imageUrl, { responseType: 'blob' })
      .pipe(
        map(res => ({ res, err: false })),
        catchError(() => this.loadDefaultImage(defaultUrl)),
        mergeMap((res: { res: Blob, err: boolean }) => this.parseImage(res)),
        first()
      )
}

<button mat-raised-button color="primary" [matMenuTriggerFor]="menu" [disabled]="disabledStatus">
  Resolve to
  <mat-icon>expand_more</mat-icon>
</button>

<mat-menu #menu="matMenu" (closed)="checkCalendar(calendar.value)">
  <button mat-menu-item (click)="setDate([2, 'h'])">2 Hours</button>
  <button mat-menu-item (click)="setDate([1, 'd'])">1 Day</button>
  <button mat-menu-item (click)="setDate([1, 'w'])">1 Week</button>
  <button mat-menu-item (click)="setDate([1, 'M'])">1 Month</button>
  <button mat-menu-item (click)="setDate([1, 'y'])">1 Year</button>
  <button mat-menu-item (click)="$event.stopPropagation();">
    Open calendar
    <input #calendar readonly class="hidden-input">
  </button>
</mat-menu>

<ba-card class="edit" [json]="resource" cardTitle="Edit resource">
  <div class="row" *ngIf="form">
    <div class="col-sm-10 offset-xs-1">
      <form class="form-horizontal" [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
        <div class="file-inputs">
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>File name</mat-label>
                <input matInput id="resourceName" type="text" placeholder="File name" [formControl]="form.controls['name']">
                <mat-error *ngIf="form.controls['name'].hasError('required')">
                  File name is required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <mat-slide-toggle color="primary" class="f-s-w slider-m-bottom" [checked]="roleOpenai" (change)="roleOpenai = !roleOpenai">
                OPENAI
              </mat-slide-toggle>
            </div>
            <div class="col-sm-6" *ngIf="roleOpenai && !openai_color_black && !openai_color_white && !openai_color_yellow && !openai_color_orange && !openai_color_red && !openai_color_purple && !openai_color_magenta && !openai_color_green && !openai_color_teal && !openai_color_blue" >
              <mat-slide-toggle color="primary" class="f-s-w slider-m-bottom" [checked]="openai_color_black_and_white" (change)="openai_color_black_and_white = !openai_color_black_and_white">
                OPENAI BLACK AND WHITE
              </mat-slide-toggle>
            </div>
            <div class="col-sm-6" *ngIf="roleOpenai && !openai_color_black_and_white && !openai_color_white && !openai_color_yellow && !openai_color_orange && !openai_color_red && !openai_color_purple && !openai_color_magenta && !openai_color_green && !openai_color_teal && !openai_color_blue" >
              <mat-slide-toggle color="primary" class="f-s-w slider-m-bottom" [checked]="openai_color_black" (change)="openai_color_black = !openai_color_black">
                OPENAI BLACK
              </mat-slide-toggle>
            </div>
            <div class="col-sm-6" *ngIf="roleOpenai && !openai_color_black_and_white && !openai_color_black && !openai_color_yellow && !openai_color_orange && !openai_color_red && !openai_color_purple && !openai_color_magenta && !openai_color_green && !openai_color_teal && !openai_color_blue" >
              <mat-slide-toggle color="primary" class="f-s-w slider-m-bottom" [checked]="openai_color_white" (change)="openai_color_white = !openai_color_white">
                OPENAI WHITE
              </mat-slide-toggle>
            </div>
            <div class="col-sm-6" *ngIf="roleOpenai && !openai_color_black_and_white && !openai_color_black && !openai_color_white && !openai_color_orange && !openai_color_red && !openai_color_purple && !openai_color_magenta && !openai_color_green && !openai_color_teal && !openai_color_blue" >
              <mat-slide-toggle color="primary" class="f-s-w slider-m-bottom" [checked]="openai_color_yellow" (change)="openai_color_yellow = !openai_color_yellow">
                OPENAI YELLOW
              </mat-slide-toggle>
            </div>
            <div class="col-sm-6" *ngIf="roleOpenai && !openai_color_black_and_white && !openai_color_black && !openai_color_white && !openai_color_yellow && !openai_color_red && !openai_color_purple && !openai_color_magenta && !openai_color_green && !openai_color_teal && !openai_color_blue" >
              <mat-slide-toggle color="primary" class="f-s-w slider-m-bottom" [checked]="openai_color_orange" (change)="openai_color_orange = !openai_color_orange">
                OPENAI ORANGE
              </mat-slide-toggle>
            </div>
            <div class="col-sm-6" *ngIf="roleOpenai && !openai_color_black_and_white && !openai_color_black && !openai_color_white && !openai_color_yellow && !openai_color_orange && !openai_color_purple && !openai_color_magenta && !openai_color_green && !openai_color_teal && !openai_color_blue" >
              <mat-slide-toggle color="primary" class="f-s-w slider-m-bottom" [checked]="openai_color_red" (change)="openai_color_red = !openai_color_red">
                OPENAI RED
              </mat-slide-toggle>
            </div>
            <div class="col-sm-6" *ngIf="roleOpenai && !openai_color_black_and_white && !openai_color_black && !openai_color_white && !openai_color_yellow && !openai_color_orange && !openai_color_red && !openai_color_magenta && !openai_color_green && !openai_color_teal && !openai_color_blue" >
              <mat-slide-toggle color="primary" class="f-s-w slider-m-bottom" [checked]="openai_color_purple" (change)="openai_color_purple = !openai_color_purple">
                OPENAI PURPLE
              </mat-slide-toggle>
            </div>
            <div class="col-sm-6" *ngIf="roleOpenai && !openai_color_black_and_white && !openai_color_black && !openai_color_white && !openai_color_yellow && !openai_color_orange && !openai_color_red && !openai_color_purple && !openai_color_green && !openai_color_teal && !openai_color_blue" >
              <mat-slide-toggle color="primary" class="f-s-w slider-m-bottom" [checked]="openai_color_magenta" (change)="openai_color_magenta = !openai_color_magenta">
                OPENAI MAGENTA
              </mat-slide-toggle>
            </div>
            <div class="col-sm-6" *ngIf="roleOpenai && !openai_color_black_and_white && !openai_color_black && !openai_color_white && !openai_color_yellow && !openai_color_orange && !openai_color_red && !openai_color_purple && !openai_color_magenta && !openai_color_teal && !openai_color_blue" >
              <mat-slide-toggle color="primary" class="f-s-w slider-m-bottom" [checked]="openai_color_green" (change)="openai_color_green = !openai_color_green">
                OPENAI GREEN
              </mat-slide-toggle>
            </div>
            <div class="col-sm-6" *ngIf="roleOpenai && !openai_color_black_and_white && !openai_color_black && !openai_color_white && !openai_color_yellow && !openai_color_orange && !openai_color_red && !openai_color_purple && !openai_color_magenta && !openai_color_green && !openai_color_blue" >
              <mat-slide-toggle color="primary" class="f-s-w slider-m-bottom" [checked]="openai_color_teal" (change)="openai_color_teal = !openai_color_teal">
                OPENAI TEAL
              </mat-slide-toggle>
            </div>
            <div class="col-sm-6" *ngIf="roleOpenai && !openai_color_black_and_white && !openai_color_black && !openai_color_white && !openai_color_yellow && !openai_color_orange && !openai_color_red && !openai_color_purple && !openai_color_magenta && !openai_color_green && !openai_color_teal" >
              <mat-slide-toggle color="primary" class="f-s-w slider-m-bottom" [checked]="openai_color_blue" (change)="openai_color_blue = !openai_color_blue">
                OPENAI BLUE
              </mat-slide-toggle>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button mat-raised-button type="submit" class="btn-save" [disabled]="!form.valid"
                color="primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ba-card>
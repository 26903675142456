import { IUserJson } from '../interfaces/user'
import * as moment from 'moment'
import UserAddress from './address.model'
import { AppStorage } from '../services/app-storage.service'
import {
  get as _get,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  isNull as _isNull,
  some as _some,
  words as _words
} from 'lodash'

export class Account {
  private static _current: Account
  _id: string
  password?: string
  lastName?: string
  currency?: string
  address?: UserAddress
  avatar?: any
  name?: string
  language?: string
  createdAt: any
  customDomain: string
  structureCustomDomain: string
  updatedAt: any
  registeredAt: any
  gsuite: any
  registeredFrom?: {
    lat: string;
    lon: string;
    ip: string;
  }
  employee: {
    administrator?: boolean;
    artdirector?: boolean;
    marketing?: boolean;
    support?: boolean;
    stockmanager?: boolean;
    sales?: boolean;
  }
  customer: {
    developer: boolean;
    beta: boolean;
  }
  phoneNumber?: string
  options: any
  unverified?: boolean
  giftDomains: Array<String>

  static set current(user: Account) {
    this._current = user
  }

  static get current(): Account {
    if (!this._current) {
      this._current = <boolean>AppStorage.get('CURRENT_USER')
        ? Account.fromJSON(AppStorage.get('CURRENT_USER'))
        : null
    }
    return this._current
  }

  static isLoggedIn(): boolean {
    return !((_isEmpty(Account.current) || _isNull(Account.current))
      || _isEmpty(AppStorage.get('CURRENT_USER')))
  }

  static fromJSON(json: IUserJson): Account {
    const account = Object.create(Account.prototype)
    return Object.assign(account, json, {
      createdAt: moment(json.createdAt),
      updatedAt: moment(json.createdAt),
    })
  }

  static reviver(key: string, value: any): any {
    return key === '' ? Account.fromJSON(value) : value
  }

  constructor(public role: string, public username: string) {
  }

  toJSON(): IUserJson {
    return Object.assign({ username: this.username, role: this.role, _id: this._id }, this, {
      createdAt: this.createdAt.format('YYYY-MM-DD HH:mm:ss'),
      updatedAt: this.createdAt.format('YYYY-MM-DD HH:mm:ss')
    })
  }

  hasAccess(): boolean {
    return _some(['admin', 'employee'], (role: any) => this.role === role)
  }

  isRole(role: any): boolean {
    return _isEqual(this.role, role)
  }

  isSupport(): boolean {
    return this.role === 'employee' && this.employee.support
  }

  isStockmanager(): boolean {
    return this.role === 'employee' && this.employee.stockmanager
  }

  isSales(): boolean {
    return this.role === 'employee' && this.employee.sales
  }

  canAccess(roles: string[] | any): boolean {
    const _roles = _words(roles)
    return _some(_roles, (role: any) => this.role === role || _get(this.employee, role))
  }

  isAdmin(): boolean {
    return !!(this.role === 'admin' || _get(this, 'employee.administrator'))
  }

  isGod(): boolean {
    return this.role === 'admin'
  }
}

import { NgaModule } from './../../../theme/nga.module'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UserInfoCardComponent } from './user-info-card.component'
import { WebsiteUserInfoDialogComponent } from './components'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { ListChangerModule } from '../list-changer'

const IMPORTS = [
  CommonModule,
  MatDialogModule,
  MatButtonModule,
  ListChangerModule,
  NgaModule
]

@NgModule({
    declarations: [UserInfoCardComponent, WebsiteUserInfoDialogComponent],
    imports: [...IMPORTS],
    exports: [UserInfoCardComponent]
})

export class UserInfoCardModule { }

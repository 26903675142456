<div class="row upload-container" *ngIf="moduleConfig?.uploader && account.canAccess(moduleConfig.uploader)"
  [@leftSlideState]="animStatus">

  <div class="col-sm-12">
    <gallery-uploader *ngIf="moduleConfig" [uploadConfig]="moduleConfig"></gallery-uploader>
  </div>
</div>

<div class="row table-container" *ngIf="moduleConfig">
  <div class="col-sm-12">
    <gallery-images *ngIf="moduleConfig" (animStatus)="initAnim()" [imgConfig]="moduleConfig"></gallery-images>
  </div>
</div>
import {
  NgModule,
  ModuleWithProviders
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { FileUploadModule } from 'ng2-file-upload'
import { NgaModule } from '../../../theme/nga.module'
import { GalleryService } from './gallery.service'
import { GalleryListComponent } from './components/list/gallery-list.component'
import { GalleryResourceDetailsComponent } from './components/details/gallery-resource-details.component'
import { GalleryUploaderComponent } from './components/list/component/uploader/gallery-uploader.component'
import { GalleryImagesComponent } from './components/list/component/images/gallery-images.component'
import { GalleryResourceEditComponent } from './components/details/components/edit/gallery-resource-edit.component'
import { BasicTableModule } from '../basic-table/basic-table.module'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ConfirmDialogModule } from '../confirm-dialog'


const COMPONENTS = [
  GalleryResourceDetailsComponent,
  GalleryResourceEditComponent,
  GalleryListComponent,
  GalleryUploaderComponent,
  GalleryImagesComponent,
]

const IMPORTS = [
  ConfirmDialogModule,
  MatTooltipModule,
  MatCardModule,
  MatInputModule,
  MatProgressBarModule,
  MatButtonModule,
  MatIconModule,
  MatSlideToggleModule,
  NgaModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FileUploadModule,
  BasicTableModule
]

const RESOLVERS = [
  GalleryService
]

@NgModule({
  imports: [...IMPORTS],
  providers: [...RESOLVERS],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS]
})

export class GalleryModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: GalleryModule,
      providers: [...RESOLVERS]
    }
  }
}
